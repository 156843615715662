import React, { useState } from "react";
// import About from "./components/About";
import useUsers from "../../hooks/useUsers";
import ModifyModal from "./components/ModifyModal";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";

export default function MyInfo() {
  //   const { user } = useAuthContext();
  //   console.log("user", user);

  const {
    userTeacherInfoQuery: { isLoading, error, data: userData },
  } = useUsers();

  const [modalOpen, setModalOpen] = useState(false);

  // console.log("userData", userData);

  //   const userData = {};

  return (
    <div className="vw-100">
      {isLoading && <p>Loading...</p>}
      {error && <p>error...</p>}
      {modalOpen && (
        <ModifyModal setModalOpen={setModalOpen} userData={userData} />
      )}
      <div className="container">
        <div className="row border rounded mt-2">
          <div className="ps-3 pt-3 pb-3 col-md-3">
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => setModalOpen(true)}
              >
                수정
              </button>
              <HelpHyperLink link={"https://youtu.be/R6JAFDF2bZ4"} size={25} />
            </div>
            <div className="text-center">
              <img
                src={
                  userData && userData.profile_link !== null
                    ? userData.profile_link
                    : "/images/default_profile.png"
                }
                className="navbarImg dropdown-toggle"
                alt="내사진"
                role="button"
                height="150"
                width="150"
                style={{ objectFit: "cover", borderRadius: 9999 }}
                onClick={() => setModalOpen(true)}
              />
            </div>
            <div>
              <span className="fullname">{userData?.fullname}</span>
            </div>
            <div>
              <span className="username">{userData?.username}</span>
            </div>
            <div>
              <span className="gender">{userData?.gender}</span>
            </div>
            <div>
              <span className="birthday">
                {userData?.birthday && userData.birthday}
              </span>
            </div>
            <div>
              <span className="schoolRole">
                {userData?.role === "teacher" ? "교직원" : ""}
              </span>
            </div>
            <div>
              <span className="email">{userData?.email && userData.email}</span>
            </div>
          </div>
          <div className="pt-3 pb-3 col-md-9">
            <div className="position-relative" style={{ height: "150px" }}>
              <div className="ms-2 mb-1">Profile</div>
              <div className="position-relative" style={{ height: "99%" }}>
                <textarea
                  className="profileMessage form-control"
                  name="profile_message"
                  style={{ height: "83%", resize: "none" }}
                  placeholder="개발 예정입니다."
                ></textarea>
              </div>
              {/* <div className="text-end me-2 mb-2 position-absolute bottom-0 end-0">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => console.log("테스트 중")}
                >
                  저장
                </button>
              </div> */}
            </div>
            {/* <div className="mt-3 ms-2">
              <div className="mb-2">
                소속: <span></span> <span></span>
              </div>
              <div className="mb-2">
                역할: <span></span> <span></span>
                <span></span>
              </div>
              <div className="mb-2">
                그룹: <span></span> <span></span>
              </div>
            </div> */}
          </div>
        </div>
        {/* <About userData={userData} /> */}
      </div>
    </div>
  );
}
