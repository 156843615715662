import { initializeApp } from "firebase/app";
// import { v4 as uuid } from "uuid";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

// import { getDatabase, ref, get, set, remove } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY?.trim(), // trim() 추가
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN?.trim(),
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL?.trim(),
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID?.trim(),
};

initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account",
});

export async function googleLogin() {
  return signInWithPopup(auth, provider).catch(console.error);
}

export async function googleLogout() {
  return signOut(auth).catch(console.error);
}

export function onUserStateChange(callback) {
  onAuthStateChanged(auth, (user) => {
    callback(user);
  });
}

// 이메일 인증 보내기
export async function sendEmailVerification(
  email,
  username = null,
  url = "login"
) {
  const actionCodeSettings = {
    // username이 있을 때만 URL에 포함
    url: username
      ? `${process.env.REACT_APP_CLIENT_URL}${url}?username=${username}`
      : `${process.env.REACT_APP_CLIENT_URL}${url}`,
    handleCodeInApp: true,
  };
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem("emailForVerification", email);
    // username이 있을 때만 저장
    if (username) {
      window.localStorage.setItem("usernameForVerification", username);
    }
    return true;
  } catch (error) {
    console.error("Error sending email verification:", error);
    throw error;
  }
}

// 이메일 인증 링크 확인 및 처리
export async function verifyEmailLink(url) {
  try {
    if (isSignInWithEmailLink(auth, url)) {
      let storedEmail = window.localStorage.getItem("emailForVerification");
      if (!storedEmail) {
        storedEmail = window.prompt("인증에 사용한 이메일을 입력해 주세요:");
        if (!storedEmail) {
          throw new Error("이메일 입력이 필요합니다.");
        }
        const result = await signInWithEmailLink(auth, storedEmail, url);
        window.localStorage.removeItem("emailForVerification");
        return {
          user: result.user,
          email: storedEmail, // 이메일 정보도 함께 반환
        };
      } else {
        const result = await signInWithEmailLink(auth, storedEmail, url);
        window.localStorage.removeItem("emailForVerification");

        return {
          user: result.user,
          email: storedEmail, // 이메일 정보도 함께 반환
        };
      }
    } else {
      throw new Error("유효하지 않은 인증 링크입니다.");
    }
  } catch (error) {
    console.error("이메일 인증 실패:", error);
    throw error;
  }
}

// 비밀번호 재설정 보내기
export async function sendPasswordReset(email, username = null) {
  const actionCodeSettings = {
    url: `${process.env.REACT_APP_CLIENT_URL}reset-password?username=${username}`,
    handleCodeInApp: true,
  };
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem("emailForVerification", email);
    // username이 있을 때만 저장
    if (username) {
      window.localStorage.setItem("usernameForVerification", username);
    }
    return true;
  } catch (error) {
    console.error("Error sending email verification:", error);
    throw error;
  }
}
