import React, { useMemo, useState } from "react";
import { createFilteredAndSortedUniqueOptions } from "../../util/dealingArrObj";

/*
arrayData에 있는 키여야 한다! 그리고 이 arrayData는 registration에서 학년도와 username을 기준으로 organizatipn_path를 갖고 있어야 한다.
arrayData: 화면에 보에는 데이터. 보통 rows,
     displayKey: 보통 "fullname",
     valueKey: 보통 "username",
     filters: 객체로 들어와야 한다. registration 같은 경우엔 보통 { school_year: schoolYearId 또는 schoolYear, organization_path: organPath },
     uniqueKey: 보통 "username", 이걸 기준으로 unique 할 것임
     sortKey: 보통 "fullname", 또는 studentno 일 수도 있다. order 라든지
     isDesc: false,


     ... 만들다 보니 이거 user만 아니라 범용적으로 사용할 수 있겠는데?
     하지만 또 너무 범용적이면 헷갈리까 이건 user 중심으로 쓰자.

*/
export default function DropdownUniqueItems({
  displayData,
  displayKey,
  valueKey,
  filters = {},
  uniqueKey,
  sortKey,
  isDesc = false,
  handleDropdownChange,
  selectedItem,
  selectName = "users",
  dropdownTitle = "유저선택",
  required = false,
  titleValue = "",
  customInput = false,
}) {
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customValue, setCustomValue] = useState("");

  const options = useMemo(() => {
    return createFilteredAndSortedUniqueOptions({
      arrayData: displayData,
      displayKey: displayKey,
      valueKey: valueKey,
      filters: filters,
      uniqueKey: uniqueKey,
      sortKey: sortKey,
      isDesc: isDesc,
    });
  }, [displayData, displayKey, valueKey, filters, uniqueKey, sortKey, isDesc]);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    if (value === "CUSTOM") {
      setShowCustomInput(true);
      handleDropdownChange({
        target: {
          name: selectName,
          value: customValue,
          selectedOptions: [
            {
              text: customValue,
              getAttribute: (attr) => null, // data-name이 없으면 null 반환
            },
          ],
        },
      });
    } else {
      setShowCustomInput(false);
      handleDropdownChange(e);
    }
  };

  const handleCustomInputChange = (e) => {
    const value = e.target.value;
    setCustomValue(value);
    handleDropdownChange({
      target: {
        name: selectName,
        value: value,
        selectedOptions: [
          {
            text: value,
            getAttribute: (attr) => null, // data-name이 없으면 null 반환
          },
        ],
      },
    });
  };

  return (
    <div className="d-flex flex-column">
      <select
        className="form-control"
        name={selectName}
        onChange={handleSelectChange}
        value={showCustomInput ? "CUSTOM" : selectedItem || ""}
        required={required}
      >
        <option value={titleValue}>{dropdownTitle}</option>
        {displayData?.length > 0 && options}
        {customInput && <option value="CUSTOM">직접입력</option>}
      </select>
      {showCustomInput && (
        <input
          type="text"
          className="form-control p-1 mt-1"
          style={{ fontSize: "12px" }}
          value={customValue}
          onChange={handleCustomInputChange}
          placeholder="여기에 입력"
        />
      )}
    </div>
  );
}
