export default class AuthService {
  constructor(http) {
    this.http = http;
  }

  async login(userInfo) {
    return this.http.fetch("/api/auth/login", {
      method: "POST",
      body: JSON.stringify(userInfo),
    });
  }

  async me() {
    return this.http.fetch("/api/auth/me", {
      method: "GET",
    });
  }

  async logout() {
    return this.http.fetch("/api/auth/logout", {
      method: "POST",
    });
  }

  async csrfToken() {
    const resp = await this.http.fetch("/api/auth/csrf-token", {
      method: "GET",
    });
    return resp.csrfToken;
  }

  async byGoogleLogin(googleData) {
    return this.http.fetch("/api/auth/google-login", {
      method: "POST",
      body: JSON.stringify(googleData),
    });
  }
}
