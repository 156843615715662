import React, { useState, useEffect } from "react";
import styles from "./CSS/PromptModal.module.css";
import { calculateTextNumber } from "../util/etCetera";

const PromptModal = ({
  isOpen,
  onClose,
  initialState,
  handleSubmit,
  textCount = false,
  closeOnOutsideClick = true,
}) => {
  const [inputObject, setInputObject] = useState({});
  const [keyName, setKeyName] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (isOpen) {
      setInputObject(initialState);
      setKeyName(initialState.keyName || "comment");
      setTitle(initialState.title || "내용");
    }
  }, [isOpen, initialState]);

  if (!isOpen) return null;

  const handleOutsideClick = (e) => {
    if (
      closeOnOutsideClick &&
      e.target.className.includes(styles.modalOverlay)
    ) {
      onClose();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOutsideClick}>
      <div className={styles.modalContainer}>
        <div
          className="p-2 d-flex justify-content-between"
          style={{ fontSize: "16px" }}
        >
          {title}
          {textCount && inputObject[keyName] ? (
            <span> 글자수: {calculateTextNumber(inputObject[keyName])}</span>
          ) : (
            ""
          )}
        </div>
        <div className={styles.modalContent}>
          <textarea
            className="form-control"
            rows="7"
            value={inputObject[keyName] ? inputObject[keyName] : ""}
            onChange={(e) =>
              setInputObject((prev) => ({ ...prev, [keyName]: e.target.value }))
            }
          />
          <button
            className="btn btn-sm btn-primary mt-1"
            onClick={() => {
              handleSubmit(inputObject);
              onClose();
            }}
          >
            입력
          </button>
          <button className="btn btn-sm btn-warning mt-1" onClick={onClose}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromptModal;
