import React from "react";
import { useUIContext } from "../../../context/UIContext";
import useUsers from "../../../hooks/useUsers";
import { useQueryClient } from "@tanstack/react-query";

export default function HandleLinkRequestModal({
  setModalOpen,
  linkRequestInfo,
  setLinkRequestInfo,
  setIsLoadingModal,
}) {
  const { cancelLinkRequest, linkRequest } = useUsers();
  const { handleToastPromptModal, handleToastCenterTop } = useUIContext();

  const queryClient = useQueryClient();

  const handleAccept = (username) => {
    try {
      const formData = {
        username: linkRequestInfo.username,
        parentUsername: username,
        status: "linked",
      };

      setIsLoadingModal(true);
      //username 존재 체크용으로 잘 쓰여야겠는데
      linkRequest.mutate(formData, {
        onSuccess: async (success) => {
          queryClient.invalidateQueries(["usersConditions"]);
          setIsLoadingModal(false);
          setLinkRequestInfo((prevInfo) => ({
            ...prevInfo,
            guardian: prevInfo.guardian.map((user) =>
              user.username === username ? { ...user, status: "linked" } : user
            ),
          }));
        },
        onError: (error) => {
          alert(error);
          setIsLoadingModal(false);
        },
      });
    } catch (error) {
      handleToastCenterTop(error.message || "연결 요청에 실패했습니다.");
    }
  };

  const handleCancel = (username) => {
    handleToastPromptModal(
      "연결을 취소하시겠습니까?",
      () => {
        const deleteData = {
          username: linkRequestInfo.username,
          parentUsername: username,
        };

        try {
          setIsLoadingModal(true);

          //username 존재 체크용으로 잘 쓰여야겠는데
          cancelLinkRequest.mutate(deleteData, {
            onSuccess: async () => {
              queryClient.invalidateQueries(["usersConditions"]);
              setLinkRequestInfo((prevInfo) => ({
                ...prevInfo,
                guardian: prevInfo.guardian.filter(
                  (user) => user.username !== username
                ),
              }));
              setIsLoadingModal(false);
            },
            onError: (error) => {
              alert(error);
              setIsLoadingModal(false);
            },
          });
        } catch (error) {
          handleToastCenterTop(error.message || "연결 요청에 실패했습니다.");
        }
      },
      () => {
        return;
      }
    );
  };

  return (
    <>
      <div className="modal-container">
        <div
          className="modalForUnit"
          style={{ width: "750px", maxHeight: "650px", overflowY: "auto" }}
        >
          <div className="modal-header mb-3">
            <h3>보호자 요청 관리</h3>
          </div>
          <div className="modal-content">
            <div className="mt-2">
              {(!linkRequestInfo?.guardian ||
                linkRequestInfo.guardian.length === 0) && (
                <p>연결된 보호자가 없습니다.</p>
              )}
              {linkRequestInfo?.guardian?.some(
                (g) => g.status === "pending"
              ) && (
                <div className="alert alert-info">
                  <p className="mb-0">연결 대기중:</p>
                  <ul className="list-unstyled mb-0 ms-2">
                    {linkRequestInfo?.guardian
                      ?.filter((user) => user.status === "pending")
                      .map((user) => (
                        <li
                          key={user.username}
                          className="d-flex justify-content-between align-items-center p-1 px-2 mb-1 mt-1 rounded hover-shadow"
                          style={{
                            backgroundColor: "#f8f9fa",
                            transition: "all 0.2s ease",
                            border: "1px solid #dee2e6",
                          }}
                        >
                          <span>
                            {user.fullname} ({user.username} / {user.mobile}){" "}
                            <span style={{ fontSize: "12px" }}>
                              요청일:{user.request_date}
                            </span>
                          </span>
                          <div>
                            <button
                              className="btn btn-sm btn-primary m-1"
                              type="button"
                              onClick={() => handleAccept(user.username)}
                            >
                              수락
                            </button>
                            <button
                              className="btn btn-sm btn-danger m-1"
                              type="button"
                              onClick={() => handleCancel(user.username)}
                            >
                              반려
                            </button>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              {linkRequestInfo?.guardian?.some(
                (g) => g.status === "linked"
              ) && (
                <div className="alert alert-success mb-2">
                  <p>연결 완료:</p>
                  <ul className="list-unstyled mb-0 ms-2">
                    {linkRequestInfo?.guardian
                      ?.filter((user) => user.status === "linked")
                      .map((user) => (
                        <li
                          key={user.username}
                          className="d-flex justify-content-between align-items-center p-1 px-2 mb-1 mt-1 rounded hover-shadow"
                          style={{
                            backgroundColor: "#f8f9fa",
                            transition: "all 0.2s ease",
                            border: "1px solid #dee2e6",
                          }}
                        >
                          <span>
                            {user.fullname} ({user.username} / {user.mobile}){" "}
                            <span style={{ fontSize: "12px" }}>
                              연결일:{user.request_date}
                            </span>
                          </span>
                          <button
                            className="btn btn-sm btn-danger m-1"
                            type="button"
                            onClick={() => handleCancel(user.username)}
                          >
                            연결해제
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => setModalOpen(false)}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
