import React from "react";
import { useLocation } from "react-router-dom";
import MainSidebar from "./mainNavbar/MainSidebar";
import MainNavbar from "./mainNavbar/MainNavbar";
import ToastCenterTop from "./ToastCenterTop";
import AlertModal from "./AlertModal";
import ToastCenterBottom from "./ToastCenterBottom";
import ToastPromptTop from "./ToastPromptTop";
import ToastConfirmTop from "./ToastConfirmTop";

const Layout = ({ children }) => {
  const location = useLocation();
  const hideNavigation = [
    "/login",
    "/email-verification",
    "/reset-password",
  ].includes(location.pathname);

  return (
    <>
      {!hideNavigation && <MainNavbar />}
      <ToastCenterTop />
      <ToastCenterBottom />
      <AlertModal />
      <ToastPromptTop />
      <ToastConfirmTop />
      <div className="d-flex">
        {!hideNavigation && <MainSidebar />}
        <main className="w-100">{children}</main>
      </div>
    </>
  );
};

export default Layout;
