import React, { useEffect, useState } from "react";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import RegistrationVariousRecordInputTable from "../../../unicomponents/registrationVariousRecordInputTable/RegistrationVariousRecordInputTable";
import useVariousStudentRecords from "../../../hooks/useVariousStudentRecords";
import { useUIContext } from "../../../context/UIContext";
import { calculateTextNumber } from "../../../util/etCetera";
import { checkHrteachers } from "../../../util/checkPermissions";
import { useAuthContext } from "../../../context/AuthContext";

export default function JubileeReadingIndex({
  settingData,
  inputData,
  schoolId,
  inputQueryConditions,
  hrTeachersData,
}) {
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "N/A",
    group: "",
  });

  const [queryConditions, setQueryConditions] = useState({
    schoolid: schoolId,
    school_year_id: "",
    record_list_id: inputQueryConditions?.record_list_id,
  });
  const [dataToSave, setDataToSave] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [savedBulk, setSavedBulk] = useState(false);
  const {
    variousStudentRecordsQuery: { data: variousStudentData },
    createOrUpdateVariousStudentRecords,
    createOrUpdateVariousStudentRecordsBulk,
  } = useVariousStudentRecords(queryConditions);

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();
  const { hasPermission } = useAuthContext();

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    if (filters.schoolYear === null && settingData && settingData.length > 0) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      setQueryConditions((prev) => ({
        ...prev,
        school_year_id: initialSchoolYearId,
      }));
    }
  }, [filters.schoolYear, settingData]);

  useEffect(() => {
    if (variousStudentData) {
      const studentDataFilteredByGroup = variousStudentData.filter(
        (r) => r.group === filters.group
      );
      setDataToSave(studentDataFilteredByGroup);
    }
  }, [filters.group, variousStudentData]);

  useEffect(() => {
    if (hrTeachersData && filters.schoolYear)
      if (checkHrteachers(hrTeachersData, "", filters.schoolYear)) {
        const thisYearData = hrTeachersData.filter(
          (r) => r.schoolYear === filters.schoolYear
        );
        const splited = thisYearData[0].organization_path.split("/");

        setFilters((filters) => ({
          ...filters,
          hierarchyOneId: splited[2],
          hierarchyTwoId: splited[3],
          hierarchyThreeId: splited[4],
          organPathForSort: thisYearData[0].organization_path,
        }));
      }
  }, [filters.schoolYear, hrTeachersData]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }

    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
      }));
      setQueryConditions((prev) => ({ ...prev, school_year_id: value }));
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: "N/A",
      }));
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: "N/A",
      }));
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
      }));
    }
  };

  const handleSaveState = (e, username, inputs_id) => {
    let value = e.target.value;
    let name = e.target.name;
    const checkTagName = e.target.tagName;

    setDataToSave((prevData) => {
      const existingItem = prevData.find((item) => item.username === username);

      if (existingItem) {
        let updatedValueObject = {
          ...existingItem.value_object,
          [name]: value,
        };

        if (checkTagName === "TEXTAREA") {
          const textLengthName = name + "TextLength";

          updatedValueObject[textLengthName] = calculateTextNumber(value);
        }

        return prevData.map((item) =>
          item.username === username
            ? {
                ...item,
                value_object: updatedValueObject,
                isModified: true,
              }
            : item
        );
      } else {
        let valueObject = { [name]: value };
        return [
          ...prevData,
          {
            schoolid: schoolId,
            school_year_id: filters.schoolYearId, // 적절한 기본값을 설정합니다.
            record_list_id: inputQueryConditions?.record_list_id,
            group: filters.group, // 적절한 기본값을 설정합니다.
            username,
            value_object: valueObject,
            isModified: true,
            // 기본값을 설정합니다. 필요에 따라 다른 기본값을 설정할 수 있습니다.
          },
        ];
      }
    });
  };

  const handleSaveClick = (row) => {
    // console.log("row", row);
    // console.log("dataToSave", dataToSave);

    const trimmedDataToSave = dataToSave.map((item) => {
      const trimmedValueObject = Object.fromEntries(
        Object.entries(item.value_object).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      return { ...item, value_object: trimmedValueObject };
    });

    const toSave = trimmedDataToSave.filter((r) => r.username === row.username);
    // console.log("toSave", toSave);

    // console.log("toSave.isModified", toSave.isModified);

    if (toSave.length < 1) {
      handleToastCenterTop("변경된 내용이 없습니다.");

      return;
    }

    if (!toSave[0].isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop("변경된 내용이 없습니다.");
      return;
    }

    if (
      toSave[0] &&
      !hasPermission(
        null,
        "can_create",
        checkHrteachers(hrTeachersData, toSave[0].username, filters.schoolYear)
      )
    ) {
      handleToastCenterTop(
        "학생의 담임과 권한이 있는 사람만 저장할 수 있습니다."
      );
      return;
    }

    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecords.mutate(toSave[0], {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, toSave[0].username]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setDataToSave((currentDataSets) =>
          currentDataSets.map((dataSet) =>
            dataSet.username === toSave[0].username
              ? { ...dataSet, isModified: false }
              : dataSet
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleSaveAll = () => {
    const trimmedDataToSave = dataToSave.map((item) => {
      const trimmedValueObject = Object.fromEntries(
        Object.entries(item.value_object).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      return { ...item, value_object: trimmedValueObject };
    });

    const modifiedData = trimmedDataToSave.filter((r) => r.isModified === true);
    if (modifiedData.length < 1) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop("변경된 내용이 없습니다.");
      return;
    }
    // console.log("dataToSave:", dataToSave);
    // console.log("trimmedDataToSave:", trimmedDataToSave);
    // console.log("modifiedData:", modifiedData);

    if (
      modifiedData[0] &&
      !hasPermission(
        null,
        "can_create",
        checkHrteachers(
          hrTeachersData,
          modifiedData[0].username,
          filters.schoolYear
        )
      )
    ) {
      handleToastCenterTop(
        "학생의 담임과 권한이 있는 사람만 저장할 수 있습니다."
      );
      return;
    }

    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecordsBulk.mutate(modifiedData, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedBulk(true);
        setTimeout(() => {
          setSavedBulk(false);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setDataToSave((currentDataSets) =>
          currentDataSets.map((data) =>
            data.isModified === true ? { ...data, isModified: false } : data
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-1"></div>
        <div className="col">
          <DropdownSchoolYear
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYearId={filters.schoolYearId}
            allYears={false}
          />
        </div>
        <div className="col">
          <DropdownHierarchyOne
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyTwo
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
            hierarchyTwoId={filters.hierarchyTwoId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyThree
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyTwoId={filters.hierarchyTwoId}
            hierarchyThreeId={filters.hierarchyThreeId}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-auto">
          <h3 className="fw-bold">독서력지수</h3>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col" style={{ minWidth: "800px", overflowX: "auto" }}>
          <RegistrationVariousRecordInputTable
            organSettingInfo={filters}
            inputData={inputData}
            studentInputData={dataToSave}
            handleSaveState={handleSaveState}
            handleSaveClick={handleSaveClick}
            handleSaveAll={handleSaveAll}
            savedRows={savedRows}
            savedBulk={savedBulk}
            addfontSize="+2"
            addMinWidth=""
            hrTeachersData={hrTeachersData}
          />
        </div>
      </div>
    </>
  );
}
