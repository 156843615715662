import React from "react";
import {
  DataGrid,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

/* 
24.1.8 드디어 어느 정도 완성 된 것 같다.
columns와 rows, fieldArray만 넣으면 원하는데로 조정할 수 있다.
예시가 기니까 맨 아래에 붙인다.
*/

export default function UniTable({
  columns,
  rows,
  exportedFileName = "eduseed Table",
  setRows,
  rowModesModel,
  setRowModesModel,
  updateData,
  disableSelectRowStatus = true,
}) {
  // console.log("rows3", rows);

  const handleRowEditStop = (params, event) => {
    // console.log("handleRowEditStop", params);
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    updateData(updatedRow);
    // console.log("updatedRow Table", updatedRow);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    // console.log("handleRowModesModelChange", newRowModesModel);

    setRowModesModel(newRowModesModel);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{ fileName: exportedFileName, utf8WithBom: true }}
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <DataGrid
      style={{ minWidth: "800px" }}
      rows={rows}
      columns={columns}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={(error) => console.log("error", error)}
      getRowHeight={() => "auto"}
      getRowClassName={() => "pt-1 pb-1"}
      slots={{
        toolbar: CustomToolbar,
      }}
      initialState={{
        ...columns.initialState,
        pagination: { paginationModel: { pageSize: 50 } },
      }}
      disableRowSelectionOnClick={disableSelectRowStatus}
      // {...(disableSelectRowStatus ? { "disableRowSelectionOnClick" } : {})}
    />
  );
}

//  예시

// header 부분을 설정해 주는 것이 columns 다.
// field: 이부분이 가져온 데이터(rows)에서 키값을 찾는 부분이다.
// 근데 키값이 일치하지 않으면 빈 것으로 나타난다.
// renderCell: 은 셀 안에 내가 넣고 싶은데로 넣는 것이다.
// params는 해당 줄의 들어갈 정보이다. row 한 object의 정보이다.
// 나머지는 보면 알겠지?
//       type: "singleSelect",
//       valueOptions: [1, 2, 3, 4, 5],
//       editable: true,

//  이건 글이 글 때 줄바꿈 해주는 거다.
// renderCell: (params) => (
//         <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
//           {params.value}
//         </div>
//       ),

// 이렇게 actions로 해서 바로 실행되게끔 할 수 잇다. rendercell 안 쓰고
// type: "actions",
// getActions: (params) => [
//   <GridActionsCellItem
//     icon={<GridAddIcon />}
//     label="저장"
//     onClick={saveRow(params.row)}
//   />,
//   <GridActionsCellItem
//     icon={<GridDeleteIcon />}
//     label="삭제"
//     onClick={deleteRow(params.row)}
//   />,
// ],

//   const columns = [
//     {
//       field: "no",
//       headerAlign: "center",
//       headerName: "No.",
//       align: "center",
//       flex: 0.5,
//       renderCell: (params) => params.id,
//     },
//     {
//       field: "schools_status",
//       headerAlign: "center",
//       headerName: "패키지단계",
//       align: "center",
//       flex: 1,
//       type: "singleSelect",
//       valueOptions: [1, 2, 3, 4, 5],
//       editable: true,
//     },
//     {
//       field: "schools_usergroup",
//       headerAlign: "center",
//       headerName: "유저그룹",
//       align: "center",
//       flex: 1,
//       type: "singleSelect",
//       valueOptions: ["teacher", "student", "parent", "admin"],
//       editable: true,
//     },
//     {
//       field: "menu_id",
//       headerAlign: "center",
//       headerName: "페이지명",
//       align: "center",
//       flex: 1,
//       type: "singleSelect",
//       valueOptions: ["기본정보", "재적", "교직원관리"],
//       editable: true,
//     },
//     {
//       field: "authorities",
//       headerAlign: "center",
//       headerName: "권한단계",
//       align: "center",
//       flex: 1,
//       type: "singleSelect",
//       valueOptions: ["접근", "읽기", "쓰기", "수정", "삭제"],
//       editable: true,
//     },
//     {
//       field: "description",
//       headerAlign: "center",
//       headerName: "설명",
//       editable: true,
//       flex: 2.5,
//       renderCell: (params) => (
//         <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
//           {params.value}
//         </div>
//       ),
//     },
//     {
//       field: "schools_id",
//       headerAlign: "center",
//       headerName: "맞춤학교",
//       align: "center",
//       flex: 1,
//       editable: true,
//       type: "singleSelect",
//       getOptionValue: (value) => value.schoolid,
//       getOptionLabel: (value) => value.schools_id,
//       valueOptions: [
//         { schoolid: "1", schools_id: "밀알두레학교" },
//         { schoolid: "2", schools_id: "에듀씨드" },
//         { schoolid: "3", schools_id: "이봄학교" },
//         { schoolid: "4", schools_id: "두레줄기학교" },
//       ],
//       /*      valueOptions 는 외부 데이터에서 가공 후 정의해서 여기 넣으면 되고,
//       getOptionValue: (value) => value.schoolid, 이거는 선택된 옵션의 value (여기선 schoolid로 됨)를 가져올 수 있는 것이다.
//       아래와 같은 이런 식
//       const [selectedOption, setSelectedOption] = useState(null);
//       const handleChange = (selectedOption) => {
//        setSelectedOption(selectedOption);
//       console.log(`Selected: ${selectedOption.value}`); */
//     },
//     {
//       field: "saveDelete",
//       headerAlign: "center",
//       headerName: "저장/삭제",
//       align: "center",
//       flex: 1.5,
//       // type: "actions",
//       // getActions: (params) => [
//       //   <GridActionsCellItem
//       //     icon={<GridAddIcon />}
//       //     label="저장"
//       //     onClick={saveRow(params.row)}
//       //   />,
//       //   <GridActionsCellItem
//       //     icon={<GridDeleteIcon />}
//       //     label="삭제"
//       //     onClick={deleteRow(params.row)}
//       //   />,
//       // ],

//       renderCell: (params) => (
//         <div
//           className="text-center"
//           style={{ whiteSpace: "normal", wordWrap: "break-word" }}
//         >
//           <Button
//             variant="contained"
//             color="primary"
//             size="small"
//             style={{
//               fontSize: "0.7rem",
//               padding: "3px 6px",
//             }}
//             onClick={() => saveRow(params.row)}
//           >
//             저장
//           </Button>
//           <Button
//             variant="contained"
//             color="secondary"
//             size="small"
//             style={{ fontSize: "0.7rem", padding: "3px 6px" }}
//             onClick={() => deleteRow(params.row)}
//           >
//             삭제
//           </Button>
//         </div>
//       ),
//     },
//   ];

// 이건 1번 클릭하여 수정할 수 있게끔 하는 것으로 editable 된 것의 키 값을 모두 넣어주면 된다.
//   const fieldArray = [
//     "description",
//     "schools_usergroup",
//     "schools_status",
//     "menu_id",
//     "schools_id",
//     "schoolid",
//     "authorities",
//   ];

// rows는 그냥 서버에서 가져오는 data 이다. array 안에 object이 들어가는 구조
// 가져와서 한번 정렬하면 좋겠지.
// 숫자를 넣는 것을 못했는데, 이건 데이터에서 자체 처리후에 넣으면 될 것 같다.
// 그리고 여기에 key 값에 맞춰서 column을 정의한다.saveDelete 이것을 그냥 비워두면 된다. 안 넣어도 될 듯. 아마도
//   const rows = [
//     {
//       id: 1,
//       schools_status: 1,
//       schools_usergroup: "교직원",
//       menu_id: "기본정보",
//       authorities: "접근",
//       description: "기본정보 메뉴를 볼 수 있다.",
//       schools_id: 2,
//       schoolid: "밀알두레학교",
//       saveDelete: "",
//     },
//     {
//       id: 2,
//       schools_status: 1,
//       schools_usergroup: "교직원",
//       menu_id: "기본정보",
//       authorities: "읽기",
//       description: "메뉴가 보이고 정보를 불러올 수 있다.",
//       schools_id: 2,
//       schoolid: "밀알두레학교",
//       saveDelete: "",
//     },
//     {
//       id: 3,
//       schools_status: 1,
//       schools_usergroup: "교직원",
//       menu_id: "기본정보",
//       authorities: "쓰기",
//       description: "정보를 추가할 수 있다.",
//       schools_id: 2,
//       schoolid: "밀알두레학교",
//       saveDelete: "",
//     },
//     {
//       id: 4,
//       schools_status: 1,
//       schools_usergroup: "교직원",
//       menu_id: "기본정보",
//       authorities: "수정",
//       description: "수정 가능",
//       schools_id: 2,
//       schoolid: "밀알두레학교",
//       saveDelete: "",
//     },
//     {
//       id: 5,
//       schools_status: 1,
//       schools_usergroup: "교직원",
//       menu_id: "기본정보",
//       authorities: "삭제",
//       description: "삭제 가능",
//       schools_id: 2,
//       schoolid: "밀알두레학교",
//       saveDelete: "",
//     },
//     {
//       id: 6,
//       schools_status: 1,
//       schools_usergroup: "학생",
//       menu_id: "기본정보",
//       authorities: "접근",
//       description: "메뉴 보기",
//       schools_id: 2,
//       schoolid: "밀알두레학교",
//       saveDelete: "",
//     },
//   ];
