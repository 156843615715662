import React, { useEffect, useRef, useState } from "react";
import LeftMenuSidebar from "../../../unicomponents/LeftMenuSidebar";
import useAllForms from "../../../hooks/useAllForms";
import { useLocation } from "react-router-dom";
import useComponentsToForms from "../../../hooks/useComponentsToForms";
import DynamicComponentsRenderer from "../../../unicomponents/formComponents/DynamicComponentsRenderer";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import { useUIContext } from "../../../context/UIContext";
import SchoolLifeRecordFormButtonBar from "./components/SchoolLifeRecordFormButtonBar";
import { useReactToPrint } from "react-to-print";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import { generateAllRegisteredStudents } from "../../../util/dealingArrObj";
import { useAuthContext } from "../../../context/AuthContext";
import { checkHrteachers } from "../../../util/checkPermissions";
import SimpleAlertMessageBlock from "../../../unicomponents/SimpleAlertMessageBlock";
import LoadingInside from "../../../unicomponents/LoadingInside";

/*
고등 고정이고, "고등"이라는 이름을 기준으로 재적에서 정보 가져오고, 
그를 바탕으로 다른 정보들 다 학년도 기준으로 필터해서 가져온다.
이름 검색하여 학번 선택하면 나오는 방식

keyArray 만든 것에서, schoolYearDates를 처리해서 학년도별 시작과 끝을 정리하자. 
아... 시상 때문에 학기 period 정보도 있어야 하네.. ㅠ.ㅠ
*/

export default function SchoolLifeRecordForm() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [filters, setFilters] = useState({
    schoolYear: "",
    schoolYearId: "",
    selectedStudent: "",
  });
  const [registrationArray, setRegistrationArray] = useState([]);
  const [keyArray, setKeyArray] = useState([]);
  const [modifyMode, setModifyMode] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [studentList, setStudentList] = useState("");
  const [usernameList, setUsernameList] = useState([]);
  const [showPageNumbers, setShowPageNumbers] = useState(false);

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
    description: "",
  });

  const conditions = {
    schoolid: schoolId,
    form_category: "schoolLifeRecord",
  };

  const [formQueryConditions, setFormQueryConditions] = useState({
    schoolid: schoolId,
    form_id: "",
  });

  const {
    allFormsQuery: { data: allFormsData },
  } = useAllForms(conditions);

  const {
    componentsToFormsQuery: { data: componentsToFormsData },
  } = useComponentsToForms(formQueryConditions);

  const {
    registrationInfoQuery: { isLoading, data: registrationInfoData },
    hrTeachersQuery: { data: hrTeachersData },
  } = useRegistrationInfo({ schoolid: schoolId }, { hrTeachers: true });

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting({ schoolid: schoolId });

  const { setIsLoadingModal, handleToastCenterTop, handleToastCenterBottom } =
    useUIContext();
  const { hasPermission } = useAuthContext();

  useEffect(() => {
    if (allFormsData) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: allFormsData[0].id,
      }));
      setActiveMenu({
        menu_name: allFormsData[0].form_name,
        menu_id: allFormsData[0].id,
        description: allFormsData[0].description,
      });
    }
  }, [allFormsData]);

  useEffect(() => {
    //초기에 학생과 과정 username, fullname, 학년도가 있는 row가 되도로 개향 한다.
    if (
      settingData &&
      settingData.length > 0 &&
      registrationInfoData &&
      activeMenu?.description
    ) {
      //학생 리스트
      let finalArray;
      finalArray = generateAllRegisteredStudents(
        registrationInfoData,
        settingData
      );

      if (activeMenu.description !== "통합") {
        finalArray = finalArray.filter(
          (row) => row[1] === activeMenu.description
        );
      }
      setRegistrationArray(finalArray);
      // setStudentList(finalArray);

      if (hasPermission(null, "can_read")) {
        setStudentList(finalArray);
      } else if (checkHrteachers(hrTeachersData)) {
        const filteredRows = finalArray.filter((r) =>
          hrTeachersData.find((data) => data.username === r.username)
        );
        setStudentList(filteredRows);

        handleToastCenterBottom("담임 것만 보는 권한을 갖고 있습니다.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeMenu.description,
    registrationInfoData,
    settingData,
    hrTeachersData,
    hasPermission,
  ]);

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: item.id,
      }));
    }
    setFilters((filters) => ({
      ...filters,
      selectedStudent: "",
      schoolYearId: "",
    }));
    setSearchKey("");
    setUsernameList([]);
    setKeyArray([]);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchKey(value);
    if (value === "") {
      setUsernameList([]);
    } else if (studentList) {
      const filteredRows = studentList.filter((row) =>
        row.fullname.includes(value)
      );

      // 고유한 username을 기준으로 중복 제거
      const uniqueFilteredRows = Array.from(
        new Map(filteredRows.map((item) => [item.username, item])).values()
      );

      // 고유한 항목으로 setUsernameList 업데이트
      setUsernameList(uniqueFilteredRows);
    }
  };

  const handleSelectUsername = (e) => {
    const { value } = e.target;
    // console.log("value", value);
    setFilters((filters) => ({
      ...filters,
      selectedStudent: value,
    }));

    const makeArray = registrationArray.filter(
      (row) => row.username.toString() === value
    );

    setKeyArray(makeArray);
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
      }));
      setUsernameList([]);

      const makeArray = registrationArray.filter(
        (row) => row.school_year.toString() === selectedOptionText
      );

      setStudentList(makeArray);
    }
  };

  const handleModifyMode = (e) => {
    e.preventDefault();

    if (filters.selectedStudent) {
      if (
        !hasPermission(
          "",
          "can_update",
          checkHrteachers(hrTeachersData, filters.selectedStudent)
        )
      ) {
        handleToastCenterTop(
          "담임의 학생 외 수정은 권한이 있는 사람만 수정할 수 있습니다."
        );
        return;
      }

      const onOff = e.target.name;
      if (onOff === "modifyOn") {
        setModifyMode(false);
      } else {
        setModifyMode(true);
      }
    } else {
      console.log("학생아이디가 선택 안됨.");
      handleToastCenterTop("학생아이디 선택 후 수정할 수 있습니다.");
    }
  };

  const adminMode = () => {
    console.log("adminMode 임");
  };

  const triggerPrint = useReactToPrint({
    content: () => {
      // addPageNumbers();
      return contentToPrint.current;
    },
    documentTitle: `${keyArray[0]?.["1"] || ""}-생활기록부-${
      keyArray[0]?.fullname
    }`,
    onBeforePrint: () => {
      const content = contentToPrint.current;
      if (content) {
        content.style.width = "210mm";
        content.style.height = "auto";
      }
      console.log("before printing...");
    },
    removeAfterPrint: true,
    pageStyle: `          
     @media print {
      @page {
        size: A4;
        margin: 10mm;
        ${
          showPageNumbers
            ? `
          @bottom-center {
            content: counter(page);
            font-size: 10pt;
            margin-bottom: 5mm;
          }
        `
            : ""
        }      }
      
      body {
        counter-reset: page;
      }
      
      .print-page {
        counter-increment: page;
      }
      
      table {
        page-break-inside: auto;
      }
      
      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
      
      thead {
        display: table-header-group;
      }
      
      tfoot {
        display: table-footer-group;
      }
    }
    `,
  });

  const contentToPrint = useRef(null);
  const handlePrint = () => {
    if (filters.selectedStudent) {
      triggerPrint();
    } else {
      console.log(
        "keyArray is incomplete. Please ens[e all data is available."
      );
      handleToastCenterTop("학생과 학기를 선택 후 인쇄할 수 있습니다.");
    }
  };

  if (isLoading) {
    return <LoadingInside />;
  }

  if (!hasPermission(null, "can_access", checkHrteachers(hrTeachersData))) {
    return (
      <SimpleAlertMessageBlock message="담임 또는 접근 권한이 있는 사람만 접근할 수 있습니다." />
    );
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={allFormsData}
              extraFunction={handleQueryCondition}
              itemName="form_name"
              itemLabel="form_label"
            />
          </div>
          <div className="col-12 col-md-10">
            <div className="d-flex justify-content-end flex-wrap mt-2">
              <div style={{ width: "150px" }} className="me-1">
                <DropdownSchoolYear
                  settingData={settingData}
                  handleDropdownChange={handleDropdownChange}
                  schoolYearId={filters.schoolYearId}
                  allYears={false}
                  title="전체 학년도"
                  disabled={modifyMode}
                />
              </div>

              <div style={{ width: "100px" }} className="me-3 text-end">
                <label
                  htmlFor="searchKeyword"
                  className="col-form-label text-end"
                >
                  검색
                </label>
              </div>
              <div style={{ width: "150px" }} className="me-1">
                <input
                  type="text"
                  id="searchKeyword"
                  name="searchKeyword"
                  className="form-control"
                  placeholder="학생이름"
                  value={searchKey}
                  onChange={handleSearch}
                  disabled={modifyMode}
                />
              </div>
              <div style={{ width: "150px" }} className="me-1">
                <select
                  className="form-control"
                  name="username"
                  onChange={handleSelectUsername}
                  value={filters.selectedStudent || ""}
                  disabled={modifyMode}
                >
                  <option value="">아이디 선택</option>
                  {usernameList &&
                    usernameList.map((user) => (
                      <option key={user.id} value={user.username}>
                        {user.username}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-end flex-wrap mb-3 mt-2">
              <SchoolLifeRecordFormButtonBar
                handlePrint={handlePrint}
                handleModifyMode={handleModifyMode}
                adminMode={adminMode}
                contentToPrint={contentToPrint}
                modifyMode={modifyMode}
                showPageNumbers={showPageNumbers}
                setShowPageNumbers={setShowPageNumbers}
              />
            </div>
            <div
              id="gradeReportPrintArea"
              className="d-flex justify-content-center print-page"
              ref={contentToPrint}
            >
              <table className="" style={{ width: "210mm" }}>
                <thead>
                  <tr>
                    <td>{/* <div style={{ height: "10mm" }}></div> */}</td>
                    {/* <th colSpan="3">
                      <PageHeader />
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="1"></td>
                    <td colSpan="1" className="p-2">
                      {componentsToFormsData &&
                        componentsToFormsData.map((item, index) => (
                          // <div key={`${item.id}-${item.order}-${index}`}>
                          <DynamicComponentsRenderer
                            components={item.components}
                            customizedContents={item.customized_contents}
                            modifyMode={modifyMode}
                            setIsLoadingModal={setIsLoadingModal}
                            key={`${item.id}-${item.order}-${index}`}
                            componentsToFormId={item.id}
                            keyArray={keyArray}
                            activeMenu={activeMenu}
                          />
                          // </div>
                        ))}
                    </td>
                    <td colSpan="1"></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">
                      <div style={{ height: "10mm" }}></div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
