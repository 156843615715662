import React, { useState } from "react";
import styles from "../css/login.module.css";
import { MdClose, MdHelpOutline } from "react-icons/md";
import useUsers from "../../../hooks/useUsers";
import { sendPasswordReset } from "../../../api/firebase";

export function ResetPasswordModal({ isOpen, onClose }) {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 새로운 로딩 상태

  const { findUsernameByEmail } = useUsers();

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      findUsernameByEmail.mutate(email, {
        onSuccess: async (usernames) => {
          // username이 배열에 있는지 확인
          const isUsernameValid = Array.isArray(usernames)
            ? usernames.includes(username)
            : usernames === username;

          if (isUsernameValid) {
            try {
              await sendPasswordReset(email, username);
              setMessage("등록된 이메일로 비밀번호 재설정 링크를 보냈습니다.");
              setIsLoading(false);
              setTimeout(() => {
                setMessage("");
                setUsername("");
                setEmail("");
                onClose();
              }, 3000);
            } catch (error) {
              setIsLoading(false);
              setErrorMessage("비밀번호 재설정 이메일 전송에 실패했습니다.");
              setTimeout(() => {
                setErrorMessage("");
              }, 3000);
            }
          } else {
            setIsLoading(false);
            setErrorMessage("입력하신 아이디와 이메일이 일치하지 않습니다.");
            setTimeout(() => {
              setErrorMessage("");
            }, 3000);
          }
        },
        onError: (error) => {
          setIsLoading(false);
          setErrorMessage(error.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        },
      });
      // TODO: API call to find ID using email
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <span>비밀번호 재설정 하기</span>
          <MdClose
            size={24}
            onClick={() => {
              setUsername("");
              setEmail("");
              onClose();
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.modalContent}>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>
                아이디와 등록된 이메일로 비밀번호를 재설정하세요.
                <span
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    position: "relative",
                    top: "-6px",
                    marginLeft: "4px",
                  }}
                  onClick={() => setShowTooltip(!showTooltip)}
                  title="등록한 이메일이 없을 경우 담임 선생님 또는 관리자에게 문의하세요."
                >
                  <MdHelpOutline size={20} color="#008080" />
                  {showTooltip && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        color: "white",
                        padding: "8px",
                        borderRadius: "4px",
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        zIndex: 1000,
                      }}
                    >
                      등록한 이메일이 없을 경우 담임 선생님 또는 관리자에게
                      문의하세요.
                    </div>
                  )}
                </span>
              </label>
              <div className="mt-2">
                <span className="p-2 me-2">아이디</span>
                <input
                  className={styles.formInput}
                  type="text"
                  placeholder="본인의 아이디"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  style={{ width: "70%" }}
                />
              </div>
              <div>
                <span className="p-2 me-2">이메일</span>
                <input
                  className={styles.formInput}
                  type="email"
                  placeholder="example@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{ width: "70%" }}
                />
              </div>
            </div>
            {message && <div className={styles.success}>{message}</div>}
            {errorMessage && <div className={styles.error}>{errorMessage}</div>}

            <div className={styles.buttonContainer}>
              <button
                type="button"
                className="btn btn-sm btn-warning"
                onClick={() => {
                  setUsername("");
                  setEmail("");
                  onClose();
                }}
                disabled={isLoading}
              >
                취소
              </button>
              <button
                type="submit"
                className="btn btn-sm btn-primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm me-1" />
                ) : null}
                비밀번호 재설정 이메일 보내기
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
