import React, { useEffect, useState } from "react";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownUniqueUsersInDisplayData from "../../../unicomponents/Dropdowns/DropdownUniqueUsersInDisplayData";
import useEduActiStudents from "../../../hooks/useEduActiStudents";
import { useUIContext } from "../../../context/UIContext";
import { useQueryClient } from "@tanstack/react-query";

export default function ActivitiesByFieldsStudents({
  settingData,
  handleDropdownChange,
  schoolYear,
  hierarchyOneId,
  hierarchyTwoId,
  hierarchyThreeId,
  filters,
  selectedUser,
  selectedActivity,
  setStudentDataForDuplicateCheck,
  schoolStageId,
  eduActiStudentsData,
  isLoading,
  eduActiStudentsConditions,
  activeMenu,
  conditions,
}) {
  const optionHours =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.hours || "";

  const optionTags =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.tags || "";

  const [savedRows, setSavedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState([]);
  const [studentRows, setStudentRows] = useState([]);
  const { setIsLoadingModal } = useUIContext();

  const { updateEduActiStudents, deleteEduActiStudents } = useEduActiStudents(
    eduActiStudentsConditions
  );

  const queryClient = useQueryClient();

  //여기서 학년, 반, 대상에 따라 filter되도록 하면 된다.
  useEffect(() => {
    if (!eduActiStudentsData || !settingData) {
      return;
    }

    let updatedData = [];

    // eduActiStudentsData 배열을 수정된 객체로 매핑하여 새 배열 생성
    updatedData = eduActiStudentsData.map((studentData) => {
      // organization_path를 "/"로 분리하고 두 번째 요소(1번 인덱스)를 추출
      const pathParts = studentData.organization_path.split("/");
      // const stageId = pathParts[2]; //과정
      const yearId = pathParts[3]; //학년
      const classId = pathParts[4]; //반

      // console.log("pathParts", pathParts);
      // settingData에서 해당 stageId를 가진 객체의 group_name 찾기
      const setting = settingData.find(
        (setting) => setting.id === parseInt(studentData.hierarchy_one)
      );
      const groupName = setting ? setting.group_name : "통합"; // 해당하는 id가 없는 경우 "" 할당

      const student_year_row = settingData.find(
        (setting) => setting.id === parseInt(yearId)
      );

      const student_year = student_year_row
        ? student_year_row.group_name
        : "통합";

      const student_class_row = settingData.find(
        (setting) => setting.id === parseInt(classId)
      );
      const student_class = student_class_row
        ? student_class_row.group_name
        : "통합";

      // 원래 studentData 객체에 schoolStage 키 추가
      return {
        ...studentData,
        schoolStage: groupName,
        hierarchy_two: yearId,
        hierarchy_three: classId,
        student_year: student_year,
        student_class: student_class,
      };
    });

    setStudentDataForDuplicateCheck(updatedData);

    updatedData = updatedData.filter((row) => {
      //schoolStageId 필터링
      if (
        schoolStageId &&
        schoolStageId !== "0" &&
        row.hierarchy_one !== schoolStageId.toString()
      ) {
        return false;
      }
      // hierarchyTwoId 필터링
      if (
        hierarchyTwoId &&
        hierarchyTwoId !== "0" &&
        row.hierarchy_two !== hierarchyTwoId
      ) {
        return false;
      }

      // hierarchyThreeId 필터링
      if (
        hierarchyThreeId &&
        hierarchyThreeId !== "0" &&
        row.hierarchy_three !== hierarchyThreeId
      ) {
        return false;
      }

      // username 필터링
      if (
        selectedUser &&
        selectedUser !== "" &&
        row.username !== selectedUser
      ) {
        return false;
      }

      // 모든 조건을 통과했다면, 이 요소를 포함시킨다.
      return true;
    });

    // Check if selectedActivity is valid and if rows match selectedActivity.id
    const filteredByActivity = updatedData.filter(
      (row) => row.educational_activities_id === selectedActivity?.id
    );

    // console.log("updatedData", updatedData);
    // console.log("filteredByActivity", filteredByActivity);

    //선택된 활동이 없으면 안 보여준다. 시상과 다른 점이다.
    if (!selectedActivity?.id || filteredByActivity.length === 0) {
      setStudentRows([]);
      return;
    }

    setStudentRows(filteredByActivity);
  }, [
    eduActiStudentsData,
    hierarchyThreeId,
    hierarchyTwoId,
    schoolStageId,
    selectedActivity,
    selectedUser,
    setStudentDataForDuplicateCheck,
    settingData,
  ]);

  if (isLoading) return <div>활동을 선택하세요.</div>;

  // 일반적인 row 데이터 변경을 처리하는 함수
  const handleTrChange = (rowIndex, event) => {
    // const { value } = event.target;
    const field = event.target.name;

    const value = field.includes("extra_attributes")
      ? {
          ...studentRows[rowIndex].extra_attributes,
          [event.target.id]: event.target.value,
        }
      : event.target.value;
    // console.log("value", value);
    const updatedRows = studentRows.map((row, index) =>
      index === rowIndex ? { ...row, [field]: value, isModified: true } : row
    );
    // console.log("updatedRows tr", updatedRows);
    setStudentRows(updatedRows);
  };

  // 태그 변경을 처리하는 함수
  const handleTagChange = (tagName, event, rowIndex, tagIndex) => {
    const { value } = event.target; // 선택된 새로운 태그 값

    // 먼저 해당하는 row를 찾아서 복사한 다음
    const updatedRows = studentRows.map((row, index) => {
      if (index === rowIndex) {
        const updatedTags = row.tags.map((tag, idx) => {
          if (idx === tagIndex) {
            return { [tagName]: value }; // 태그 값을 업데이트
          }
          return tag;
        });

        return { ...row, tags: updatedTags, isModified: true };
      }
      return row;
    });

    // console.log("updatedRows", updatedRows);
    setStudentRows(updatedRows); // 상태 업데이트
  };

  const handleSubmit = (index) => {
    const dataSet = studentRows[index];
    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      return;
    }
    // 변경된 데이터 세트 처리 로직...
    // console.log("제출된 데이터 세트:", dataSet);
    setIsLoadingModal(true);
    updateEduActiStudents.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setStudentRows((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    const id = row.id;
    setIsLoadingModal(true);
    deleteEduActiStudents.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        queryClient.invalidateQueries(["eduActiStudents"]);
        setStudentRows((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-3">
          <h4>배정</h4>
        </div>
        <div className="col-7 d-flex mb-3">
          <DropdownHierarchyTwo
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={schoolYear}
            hierarchyOneId={hierarchyOneId}
            hierarchyTwoId={hierarchyTwoId}
            parentId={false}
          />

          <DropdownHierarchyThree
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={schoolYear}
            hierarchyTwoId={hierarchyTwoId}
            hierarchyThreeId={hierarchyThreeId}
          />

          <DropdownUniqueUsersInDisplayData
            displayData={studentRows}
            displayKey={"fullname"}
            valueKey={"username"}
            filters={filters}
            uniqueKey={"username"}
            sortKey={"fullname"}
            isDesc={false}
            handleDropdownChange={handleDropdownChange}
            selectedUser={selectedUser}
            selectName={"students"}
          />
        </div>
        <div className="col-2 text-end"></div>
      </div>
      <form onSubmit={handleSubmit} className="container-fluid">
        <table
          className="table table-bordered"
          style={
            activeMenu?.options?.length > 0 &&
            activeMenu?.options[0]?.[activeMenu.schoolid]
              ? { fontSize: "smaller" }
              : {}
          }
        >
          <thead>
            <tr className="table-secondary">
              <th width={50}>학년</th>
              <th width={50}>반</th>
              <th width={70}>이름</th>
              <th width={150}>활동명</th>
              {optionHours && <th width={80}>시간</th>}
              <th style={{ minWidth: "150px" }}>내용</th>
              <th width={60}></th>
            </tr>
          </thead>
          <tbody>
            {studentRows?.map((row, index) => (
              <Trs
                key={row.id}
                row={row}
                index={index}
                handleTrChange={handleTrChange}
                handleSubmit={handleSubmit}
                handleTagChange={handleTagChange}
                savedRows={savedRows}
                handleDelete={handleDelete}
                confirmDelete={confirmDelete}
                handleConfirmDelete={handleConfirmDelete}
                handleCancelDelete={handleCancelDelete}
                optionHours={optionHours}
                optionTags={optionTags}
              />
            ))}
          </tbody>
        </table>
      </form>
    </>
  );
}

function Trs({
  row,
  index,
  handleTrChange,
  handleSubmit,
  handleTagChange,
  savedRows,
  handleDelete,
  confirmDelete,
  handleConfirmDelete,
  handleCancelDelete,
  optionHours,
  optionTags,
}) {
  return (
    <>
      <tr className="align-middle text-center" style={{ fontSize: "14px" }}>
        <td className="pb-0 pt-0" style={{ fontSize: "13px" }}>
          {row?.student_year || ""}
        </td>
        <td className="pb-0 pt-0" style={{ fontSize: "13px" }}>
          {row?.student_class || ""}
        </td>
        <td className="pb-0 pt-0" style={{ fontSize: "13px" }}>
          {row.fullname}
        </td>
        <td className="pb-0 pt-0">
          <textarea
            type="text"
            className="input-like-span"
            rows={3}
            name="title"
            value={row?.title || ""}
            onChange={(e) => handleTrChange(index, e)}
          />
        </td>
        {optionHours && (
          <td className="pb-0 pt-0">
            <input
              type="text"
              className="input-like-span"
              name="extra_attributes"
              id="hours"
              value={row?.extra_attributes?.hours || ""}
              onChange={(e) => handleTrChange(index, e)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(index); // Enter 키를 눌렀을 때 handleSubmit 호출
                }
              }}
            />
          </td>
        )}
        <td className="pb-0 pt-0">
          <textarea
            type="text"
            className="input-like-span"
            rows={3}
            name="content"
            value={row.content}
            onChange={(e) => handleTrChange(index, e)}
          />
        </td>
        <td valign="middle" className="pb-0 pt-0">
          <button
            type="button"
            onClick={() => handleSubmit(index)}
            className="btn btn-sm p-1 btn-primary"
          >
            {savedRows.includes(row.id) ? "저장완료" : "저장"}
          </button>
          {confirmDelete.includes(row.id) ? (
            <>
              <button
                type="button"
                onClick={() => handleDelete(row)}
                className="btn btn-sm p-1 btn-danger mt-1"
              >
                확인
              </button>
              <button
                type="button"
                onClick={() => handleCancelDelete()}
                className="btn btn-sm p-1 btn-secondary mt-1"
              >
                취소
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={() => handleConfirmDelete(row)}
              className="btn btn-sm p-1 btn-warning mt-1"
            >
              삭제
            </button>
          )}
        </td>
      </tr>
      {optionTags && (
        <tr style={{ borderBottom: "3px solid #000" }}>
          <th className="pb-0 pt-0 align-middle">태그</th>
          <td colSpan={6} className="pb-1 pt-1">
            <div className="d-flex" style={{ fontSize: "14px" }}>
              <div
                style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}
              >
                {row?.tags?.map((tag, tagIndex) => {
                  const tagName = Object.keys(tag)[0];
                  return (
                    <div
                      key={tagIndex}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        className="text-center"
                        style={{ marginBottom: "5px" }}
                      >
                        {tagName}
                      </div>
                      <select
                        className="form-control p-1"
                        value={tag[tagName]}
                        name="tags"
                        onChange={(e) =>
                          handleTagChange(tagName, e, index, tagIndex)
                        }
                      >
                        <option value="1">매우약함</option>
                        <option value="2">약함</option>
                        <option value="3">보통</option>
                        <option value="4">강함</option>
                        <option value="5">매우강함</option>
                      </select>
                    </div>
                  );
                })}
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
