import React from "react";
import styles from "./CSS/ToastPromptTop.module.css"; // 같은 스타일 사용
import { useUIContext } from "../context/UIContext";

export default function ToastConfirmTop() {
  const { toastConfirmModalState, setToastConfirmModalState } = useUIContext();

  if (!toastConfirmModalState.visible) return null;

  const handleConfirm = () => {
    if (toastConfirmModalState.onConfirm) toastConfirmModalState.onConfirm();
    setToastConfirmModalState({
      visible: false,
      message: "",
      onConfirm: null,
    });
  };

  const handleCancel = () => {
    if (toastConfirmModalState.onCancel) toastConfirmModalState.onCancel();
    setToastConfirmModalState({
      visible: false,
      message: "",
      onConfirm: null,
    });
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <p>{toastConfirmModalState.message}</p>
          <div className="d-flex justify-content-end gap-2">
            <button onClick={handleConfirm} className="btn btn-danger">
              확인
            </button>
            <button onClick={handleCancel} className="btn btn-secondary">
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
