import React, { useEffect, useState } from "react";
import { useUIContext } from "../../../context/UIContext";
import InsertStudentsSidebar from "../../../unicomponents/InsertStudentsSidebar";
import useEducationalActivities from "../../../hooks/useEducationalActivities";

export default function ActivitiesByFieldsActivity({
  rows,
  selectedActivity,
  setSelectedActivity,
  schoolStageId,
  schoolStageOrganPath,
  setFilters,
  studentDataForDuplicateCheck,
  schoolYear,
  createEduActiStudents,
  createEduActiStudentsBulk,
  selectedTerm,
  activeMenu,
  conditions,
}) {
  const optionCategory =
    activeMenu?.options?.[0]?.[conditions?.schoolid]?.category || "";

  const optionEndDate =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.endDate || "";

  const optionHours =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.hours || "";

  const optionTermName =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.termName || "";

  const optionTags =
    activeMenu?.options?.[0]?.[activeMenu?.schoolid]?.tags || "";

  const [isAdded, setIsAdded] = useState([]);
  const [isAddedAll, setIsAddedAll] = useState(false);
  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  function handleActivityChange(e) {
    const { value } = e.target;
    const selected = rows.filter(
      (row) => row.id.toString() === value.toString()
    );

    setSelectedActivity(selected[0]);
    if (selected.length > 0) {
      setFilters((filters) => ({
        ...filters,
        educational_activities_id: selected[0].id,
      }));
    } else {
      setFilters((filters) => ({
        ...filters,
        educational_activities_id: 0,
      }));
    }
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const { updateEducationalActivities } = useEducationalActivities();

  // selectedActivity 값이 변경될 때마다 selectedOptions 상태를 업데이트
  useEffect(() => {
    if (selectedActivity && selectedActivity.tags) {
      // console.log("selectedActivity", selectedActivity);
      // const newSelectedOptions = selectedActivity.tags.reduce((acc, tag) => {
      //   const key = Object.keys(tag)filters;
      //   return { ...acc, [key]: tag[key] };
      // }, {});

      // console.log("selectedActivity.tags", selectedActivity.tags);
      setSelectedOptions(selectedActivity.tags);
    }
  }, [selectedActivity]);

  // "저장" 버튼 클릭 핸들러
  const saveChanges = (e) => {
    e.preventDefault();
    const dataSet = { id: e.target.name, tags: selectedOptions };

    setIsLoadingModal(true);
    updateEducationalActivities.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  // 선택 옵션 변경 핸들러
  const handleSelectChange = (tagName, newValue) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.map((option) => {
        // Object.keys(option)[0]은 현재 옵션 객체의 키를 가져옵니다.
        // 이 키가 tagName과 일치하면, 새 값을 가진 객체를 반환합니다.

        if (Object.keys(option)[0] === tagName) {
          return { [tagName]: newValue };
        }
        // 일치하지 않는 경우, 원래 객체를 그대로 반환합니다.
        return option;
      })
    );
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddClick = (row) => {
    if (!selectedActivity || Object.keys(selectedActivity).length === 0) {
      // selectedActivity가 null이거나, 빈 객체일 때 실행할 코드
      alert("활동을 먼저 선택해 주세요.");

      return;
    }

    const filteredData = studentDataForDuplicateCheck.filter(
      (r) =>
        r.school_year === schoolYear &&
        r.hierarchy_one === selectedActivity.hierarchy_one
    );
    const dataForDuplicateUser = filteredData.filter(
      (r) => r.educational_activities_id === selectedActivity.id
    );
    if (dataForDuplicateUser.find((r) => r.username === row.username)) {
      alert("이미 배정된 학생입니다.");
      return;
    }

    // tags 배열 내의 각 객체를 순회하며 값을 3으로 설정
    const generatedTags = (selectedActivity.tags || []).map((tagObject) => {
      // 객체 내의 모든 키에 대해 값을 3으로 설정
      const updatedTagObject = {};
      Object.keys(tagObject).forEach((key) => {
        updatedTagObject[key] = "3"; // 값으로 문자열 "3"을 설정, 숫자로 설정하려면 3으로 변경
      });
      return updatedTagObject;
    });

    //데이터베이스에 저장할 데이터 만들기
    const addDataSet = {
      schoolid: selectedActivity.schoolid,
      school_year: selectedActivity.school_year,
      educational_activities_id: selectedActivity.id,
      username: row.username,
      title: selectedActivity.title,
      content: selectedActivity.content,
      on_schoollife_record: "등재",
      extra_attributes: {
        ...Object.fromEntries(
          Object.entries(selectedActivity.extra_attributes).map(
            ([key, value]) => [key, value || ""]
          )
        ),
      },
      tags: generatedTags,
    };

    setIsLoadingModal(true);
    createEduActiStudents.mutate(addDataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setIsAdded((prev) => [...prev, row.id]);
        setTimeout(() => {
          setIsAdded([]);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleAddAllClick = (rows) => {
    if (!selectedActivity || Object.keys(selectedActivity).length === 0) {
      // selectedActivity가 null이거나, 빈 객체일 때 실행할 코드
      alert("활동을 먼저 선택해 주세요.");

      return;
    }

    const bulkDataSets = [];
    const addedStudents = []; // 성공적으로 추가될 학생들의 이름을 저장할 배열
    const duplicateStudents = []; // 중복으로 추가되지 않은 학생들의 이름을 저장할 배열
    let message;

    rows.forEach((row) => {
      const filteredData = studentDataForDuplicateCheck.filter(
        (r) =>
          r.school_year === schoolYear &&
          r.hierarchy_one === selectedActivity.hierarchy_one
      );

      const isDuplicate = filteredData.some(
        (r) =>
          r.educational_activities_id === selectedActivity.id &&
          r.username === row.username
      );

      if (isDuplicate) {
        duplicateStudents.push(row.fullname);
        return;
      }
      const generatedTags = (selectedActivity.tags || []).map((tagObject) => {
        const updatedTagObject = {};
        Object.keys(tagObject).forEach((key) => {
          updatedTagObject[key] = "3";
        });
        return updatedTagObject;
      });

      const addDataSet = {
        schoolid: selectedActivity.schoolid,
        school_year: selectedActivity.school_year,
        educational_activities_id: selectedActivity.id,
        username: row.username,
        title: selectedActivity.title,
        content: selectedActivity.content,
        on_schoollife_record: "등재",
        extra_attributes: {
          ...Object.fromEntries(
            Object.entries(selectedActivity.extra_attributes).map(
              ([key, value]) => [key, value || ""]
            )
          ),
        },
        tags: generatedTags,
      };

      addedStudents.push(row.fullname);
      bulkDataSets.push(addDataSet);
    });

    if (bulkDataSets.length > 0) {
      setIsLoadingModal(true);
      createEduActiStudentsBulk.mutate(bulkDataSets, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          // 성공적으로 추가된 row.id들을 setIsAddedAll로 처리
          setIsAddedAll(true);
          setTimeout(() => {
            setIsAddedAll(false);
          }, 1500);

          message = `${
            addedStudents.length
          }명이 성공적으로 추가되었습니다. <br>- ${addedStudents.join(", ")}`;

          if (duplicateStudents.length > 0) {
            message = `${
              addedStudents.length
            }명이 성공적으로 추가되었습니다. <br>- ${addedStudents.join(
              ", "
            )} <br> 다음 ${
              duplicateStudents.length
            }명은 중복으로 인해 추가되지 않았습니다. <br> - ${duplicateStudents.join(
              ", "
            )}`;
          }
          handleToastCenterTop(message, 4000);
        },
        onError: (error) => {
          setIsLoadingModal(false);
          console.error(error);
          alert("에러 발생: " + error.message);
        },
      });
    } else {
      if (duplicateStudents.length > 0) {
        message = `다음 ${
          duplicateStudents.length
        }명은 중복으로 인해 추가되지 않았습니다. <br> - ${duplicateStudents.join(
          ", "
        )}`;
      }
      handleToastCenterTop(message, 4000);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold">
          {activeMenu?.activity_name && activeMenu.activity_name}
        </h3>
        <button
          className="btn btn-sm btn-secondary mt-2"
          onClick={toggleSidebar}
        >
          배정
        </button>
      </div>
      <InsertStudentsSidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        handleAddClick={handleAddClick}
        isAdded={isAdded}
        isAddedAll={isAddedAll}
        handleAddAllClick={handleAddAllClick}
        schoolStageId={schoolStageId}
        schoolStageOrganPath={schoolStageOrganPath}
      />
      <table
        className="table mb-1"
        style={
          activeMenu?.options?.length > 0 &&
          activeMenu?.options[0]?.[conditions.schoolid]
            ? { fontSize: "smaller" }
            : {}
        }
      >
        <thead>
          <tr className="table-secondary">
            <th style={{ width: "8%" }}>과정</th>
            <th style={{ minWidth: "80px", width: "160px" }}>활동명</th>
            {optionCategory && <th style={{ width: "100px" }}>구분</th>}
            {optionTermName && <th style={{ width: "100px" }}>학기</th>}
            <th style={{ width: "100px" }}>시작날짜</th>
            {optionEndDate && <th style={{ width: "100px" }}>종료날짜</th>}
            {optionHours && <th style={{ width: "80px" }}>시간</th>}
            <th style={{ width: "35%" }}>내용</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selectedActivity?.schoolStage}</td>
            <td>
              <select
                className="select-like-span"
                name="educational_activities"
                value={selectedActivity?.id || ""}
                onChange={handleActivityChange}
              >
                <option value="0">선택</option>
                {rows &&
                  (optionTermName
                    ? rows.filter(
                        (r) => r.extra_attributes.termName === selectedTerm
                      )
                    : rows
                  ).map((row, index) => (
                    <option key={index} value={row.id}>
                      {row.title}
                    </option>
                  ))}
              </select>
            </td>
            {console.log("selectedActivity", selectedActivity)}
            {optionCategory && (
              <td>{selectedActivity?.extra_attributes?.category}</td>
            )}
            {optionTermName && (
              <td>{selectedActivity?.extra_attributes?.termName}</td>
            )}
            <td>{selectedActivity.start_date}</td>
            {optionEndDate && (
              <td>{selectedActivity?.extra_attributes?.end_date}</td>
            )}
            {optionHours && (
              <td>{selectedActivity?.extra_attributes?.hours}</td>
            )}
            <td>{selectedActivity?.content}</td>
          </tr>
        </tbody>
      </table>
      {optionTags && (
        <form>
          <div className="d-flex" style={{ fontSize: "14px" }}>
            <div className="me-2 ms-2">
              <div
                className="text-center"
                style={{ fontWeight: "bold", marginBottom: "6px" }}
              >
                태그
              </div>
              <button
                onClick={saveChanges}
                className="btn btn-sm btn-primary"
                name={selectedActivity?.id}
                style={{ fontSize: "14px" }}
              >
                저장
              </button>
            </div>
            <div
              style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}
            >
              {selectedActivity?.tags?.map((tag, index) => {
                const tagName = Object.keys(tag)[0];
                const optionValue =
                  selectedOptions.find(
                    (option) => Object.keys(option)[0] === tagName
                  )?.[tagName] ?? "";

                return (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      className="text-center"
                      style={{ marginBottom: "5px" }}
                    >
                      {tagName}
                    </div>
                    <select
                      className="form-control p-1"
                      value={optionValue}
                      onChange={(e) =>
                        handleSelectChange(tagName, e.target.value)
                      }
                    >
                      <option value="1">매우약함</option>
                      <option value="2">약함</option>
                      <option value="3">보통</option>
                      <option value="4">강함</option>
                      <option value="5">매우강함</option>
                    </select>
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      )}
    </>
  );
}
