import React from "react";

function LoginBoard() {
  return (
    <div className="loginBoard d-none d-sm-block text-center">
      <img
        alt="에듀씨드"
        src="/images/eduseedLogo.png"
        width="370px"
        height="370px"
      />
    </div>
  );
}

export default LoginBoard;
