import React from "react";
import styles from "./CSS/ToastPromptTop.module.css";
import { useUIContext } from "../context/UIContext";

export default function ToastPromptTop() {
  const { toastPromptModalState, setToastPromptModalState } = useUIContext();

  if (!toastPromptModalState.visible) return null;

  const handleConfirm = () => {
    if (toastPromptModalState.onConfirm) toastPromptModalState.onConfirm();
    setToastPromptModalState({
      visible: false,
      message: "",
      onConfirm: null,
      onCancel: null,
    });
  };

  const handleCancel = () => {
    if (toastPromptModalState.onCancel) toastPromptModalState.onCancel();
    setToastPromptModalState({
      visible: false,
      message: "",
      onConfirm: null,
      onCancel: null,
    });
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <p>{toastPromptModalState.message}</p>
          <button onClick={handleConfirm} className="btn btn-primary">
            확인
          </button>
          <button onClick={handleCancel} className="btn btn-secondary">
            취소
          </button>
        </div>
      </div>
    </div>
  );
}
