import React, { useState } from "react";
import styles from "../css/login.module.css";
import { MdClose, MdHelpOutline } from "react-icons/md";
import useUsers from "../../../hooks/useUsers";

export function FindIdModal({ isOpen, onClose }) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  const { findUsernameByEmail } = useUsers();

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      findUsernameByEmail.mutate(email, {
        onSuccess: async (username) => {
          const formattedUsernames = Array.isArray(username)
            ? username.length > 1
              ? username.join(", ")
              : username[0]
            : username;

          setMessage(formattedUsernames);
        },
        onError: (error) => {
          setMessage(error.message);
        },
      });
      // TODO: API call to find ID using email
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <span>아이디 찾기</span>
          <MdClose size={24} onClick={onClose} style={{ cursor: "pointer" }} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.modalContent}>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>
                등록한 이메일을 입력하여 아이디를 찾으세요.
                <span
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    position: "relative",
                    top: "-6px",
                    marginLeft: "4px",
                  }}
                  onClick={() => setShowTooltip(!showTooltip)}
                  title="등록한 이메일이 없을 경우 담임 선생님 또는 관리자에게 문의하세요."
                >
                  <MdHelpOutline size={20} color="#008080" />
                  {showTooltip && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        color: "white",
                        padding: "8px",
                        borderRadius: "4px",
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        zIndex: 1000,
                      }}
                    >
                      등록한 이메일이 없을 경우 담임 선생님 또는 관리자에게
                      문의하세요.
                    </div>
                  )}
                </span>
              </label>
              <input
                className={styles.formInput}
                type="email"
                placeholder="example@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {message && (
              <div
                className={`${styles.message} ${
                  message.includes("@") ? styles.success : styles.error
                }`}
              >
                {message.includes("@") ? (
                  <>
                    등록된 이메일과 일치하는 아이디
                    <br />
                    {message}
                  </>
                ) : (
                  message
                )}
              </div>
            )}
            <div className={styles.buttonContainer}>
              <button
                type="button"
                className="btn btn-sm btn-warning"
                onClick={onClose}
              >
                취소
              </button>
              <button type="submit" className="btn btn-sm btn-primary">
                아이디 찾기
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
