export default class FlexibleDataService {
  constructor(http) {
    this.http = http;
  }

  async findAllFlexibleData(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/flexibledata/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createOrUpdateFlexibleData(data) {
    return this.http.fetch(`/api/flexibledata/createorupdate`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async deleteFlexibleData(data) {
    return this.http.fetch(`/api/flexibledata/delete`, {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }
}
