import React, { useState } from "react";

const ImageUploader = ({ initialImage, onFileSelect, name, id, options }) => {
  const [preview, setPreview] = useState(initialImage);
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    processFile(selectedFile);
  };

  const processFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);

      if (onFileSelect) {
        onFileSelect({
          value: file,
          name,
        });
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const file = e.dataTransfer.files[0]; // 드래그 앤 드롭된 파일
    processFile(file);
  };

  const width = options[0]?.width ? `${options[0].width}px` : "100%"; // 숫자면 px 추가, 기본값은 100%
  const height = options[0]?.height ? `${options[0].height}px` : "100%"; // 숫자면 px 추가, 기본값은 100%
  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragging ? "2px solid blue" : "1px dashed gray",
        textAlign: "center",
        padding: "2px",
        position: "relative",
        width,
        height,
      }}
    >
      {preview ? (
        <img
          src={preview}
          alt="Preview"
          style={{
            width: "100%", // 부모(td)의 너비를 따름
            height: "100%", // 부모(td)의 높이를 따름
            objectFit: "contain", // 원래 비율 유지
            display: "block", // 이미지가 td의 중앙에 맞춰지도록 block 설정
          }}
          onClick={() => document.getElementById(id).click()}
        />
      ) : (
        <p
          onClick={() => document.getElementById(id).click()}
          style={{
            color: "gray",
            cursor: "pointer",
            margin: "0",
          }}
        >
          이미지 추가 (드래그 앤 드롭 또는 클릭)
        </p>
      )}
      <input
        id={id}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        name={name}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ImageUploader;
