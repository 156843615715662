import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyEmailLink } from "../../../api/firebase";
import { useUIContext } from "../../../context/UIContext";
import useUsers from "../../../hooks/useUsers";
import { validatePasswordParent } from "../../../util/validate";

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verifiedUsername, setVerifiedUsername] = useState("");
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const { setIsLoadingModal, setAlertModalState } = useUIContext();
  const { resetPassword } = useUsers();

  useEffect(() => {
    const verifyEmail = async () => {
      const url = window.location.href;
      const urlParams = new URLSearchParams(window.location.search);
      const username = urlParams.get("username");
      const oobCode = urlParams.get("oobCode"); // Firebase action code

      // username과 oobCode 모두 필요
      if (!username || !oobCode) {
        setError("유효하지 않은 인증 링크입니다.");
        return;
      }

      setIsLoadingModal(true);
      try {
        const result = await verifyEmailLink(url);
        setVerifiedEmail(result.email);
        setVerifiedUsername(username);
        setIsVerified(true);
      } catch (error) {
        setError(error.message || "이메일 인증에 실패했습니다.");
      } finally {
        setIsLoadingModal(false);
      }
    };

    verifyEmail();
  }, [setIsLoadingModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("비밀번호가 일치하지 않습니다.");
      return;
    }

    if (!validatePasswordParent(newPassword)) {
      setMessage(
        "영문, 숫자, 특수문자 중 최소 2가지 이상 포함하여 8자 이상이어야 합니다."
      );
      return;
    }

    setIsLoadingModal(true);
    try {
      await resetPassword.mutate(
        {
          username: verifiedUsername,
          password: newPassword,
          email: verifiedEmail,
        },
        {
          onSuccess: () => {
            setIsLoadingModal(false);
            setAlertModalState({
              visible: true,
              message: "비밀번호가 성공적으로 변경되었습니다.",
            });
            setTimeout(() => {
              navigate("/login");
            }, 1500);
          },
          onError: (error) => {
            setIsLoadingModal(false);
            setMessage(error.message || "비밀번호 변경에 실패했습니다.");
          },
        }
      );
    } catch (error) {
      setIsLoadingModal(false);
      setMessage(error.message || "비밀번호 변경에 실패했습니다.");
    }
  };

  if (error) {
    return <div className="alert alert-danger mt-3">{error}</div>;
  }

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          {isVerified ? (
            <form onSubmit={handleSubmit}>
              <h4 className="mb-4">
                아이디: {verifiedUsername || ""}의 새 비밀번호 설정
              </h4>
              <div className="mb-3">
                <label className="form-label">새 비밀번호</label>
                <input
                  type="password"
                  className="form-control"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  minLength={8}
                  placeholder="영문, 숫자, 특수문자 중 최소 2가지 이상 포함하여 8자 이상"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">비밀번호 확인</label>
                <input
                  type="password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  minLength={8}
                  placeholder="영문, 숫자, 특수문자 중 최소 2가지 이상 포함하여 8자 이상"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                비밀번호 변경
              </button>
              {message && (
                <div className="alert alert-danger mt-3">{message}</div>
              )}
            </form>
          ) : (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
