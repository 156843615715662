import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { googleLogin, googleLogout, onUserStateChange } from "../api/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import LoginModal from "../unicomponents/Modals/LoginModal";

const AuthContext = createContext();
const csrfRef = createRef();

//이것이 App.js에 들어가서 모든 파일(children)을 감싸고 이 함수 안에 있는 것들을 모든 파일에서 쓸 수 있도록 제공한다.
//정확히 말하면 모든 것은 아니고 App만 감싸는 것이고 developer 페이지는 여기에 쌓이지 않는다.
/*전역적으로 쓸 수 있는 기능은 다음과 같다.
  googleLogin, googleLogout, onUserStateChange(구글로그인상태 체크)
  login(userInfo), logout, 
  userInfo는 다음과 같다. { username: "", password: "", schoolid: "", role: "teacher", }
  로그인 성공시 다음과 같은 정보를 리턴한다. token, username, schoolid

  useAuthContext 로 다른 컴포넌트에서 불러 올 수 있다. 
  예시:  const { login } = useAuthContext();
        const { user } = useAuthContext();
           user = { token: "", username: "", schoolid: "", role: "" }

  useEffect로 렌더링 때마다 작동하는 것이 있다. (보안 관련)
  onUserStateChange(구글로그인상태 체크)
  authService.csrfToken() - csrfToken 체크
  authService.me() - 유저로그인 상태 체크 / 로그인 상태 아닐시 로그인 페이지로 리다이렉트 한다.
  로그인 상태 체크 후 user를 다음과 같은 정보로 리셋한다. token, username, schoolid
*/
export function AuthContextProvider({ authService, children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const segments = path.split("/");
  const findSchool = segments[1];
  const findRole = segments[2];
  // 새로 추가: address 값을 계산하여 Context에서 사용할 수 있도록 추가
  const address = "/" + segments[3] || ""; // URL 구조에 따라 슬래시 포함한 address 추출

  const [googleUser, setGoogleUser] = useState();
  const [user, setUser] = useState();
  const [permissions, setPermissions] = useState([]);
  const [csrfToken, setCsrfToken] = useState(undefined);
  const [isModalOpen, setModalOpen] = useState(false); // 모달 상태 추가
  const [selectedChild, setSelectedChild] = useState(() => {
    try {
      // 기본적인 검증 추가
      const stored = sessionStorage.getItem("selectedChild");
      if (!stored) return "";

      return stored;
    } catch {
      return "";
    }
  });

  useImperativeHandle(csrfRef, () => csrfToken);
  useImperativeHandle(csrfRef, () => csrfToken);

  useEffect(() => {
    onUserStateChange((googleUser) => {
      setGoogleUser(googleUser);
    });
  }, []);

  useEffect(() => {
    authService.csrfToken().then(setCsrfToken).catch(console.error);
  }, [authService]);

  useEffect(() => {
    const handleUnauthorized = () => {
      setModalOpen(true);
    };

    window.addEventListener("unauthorized", handleUnauthorized);

    return () => {
      window.removeEventListener("unauthorized", handleUnauthorized);
    };
  }, []);

  // 부모-자녀 매칭 검증 함수
  const checkParentChildMatch = useCallback(() => {
    if (user?.role === "parent" && selectedChild) {
      const linkedChildren =
        user.guardian?.filter((g) => g.status === "linked") || [];
      const isValidChild = linkedChildren.some(
        (g) => g.username === selectedChild
      );

      if (!isValidChild) {
        // 유효하지 않은 선택인 경우 첫 번째 연결된 자녀로 리셋
        const firstLinkedChild = linkedChildren[0]?.username || "";
        setSelectedChild(firstLinkedChild);
        sessionStorage.setItem("selectedChild", firstLinkedChild);
        alert("경고! 잘못된 정보입니다. 관리자에게 보고됩니다.");
        console.warn(
          "Invalid child selection detected, reset to:",
          firstLinkedChild
        );
      }
    }
  }, [user, selectedChild]);

  useEffect(() => {
    checkParentChildMatch();
  }, [selectedChild, checkParentChildMatch]);

  useEffect(() => {
    if (!user) {
      authService
        .me()
        .then(({ permissions, ...userData }) => {
          setUser(userData);
          setPermissions(permissions); // 권한 따로 저장
        })
        .catch((error) => {
          console.error("Error:", error);
          if (error.response && error.response.status === 401) {
            setModalOpen(true); // 401 발생 시 모달 열기
          }
        });
    }
  }, [authService, user]);

  //auth.me도 구글로그인 상태 변화에 따라 반응하는 것도 1개 더 만들어야겠네.

  // 로그인된 상태에서 대시보드로 리다이렉트
  useEffect(() => {
    if (user) {
      let userRole = user.role === "admin" ? "teacher" : user.role;

      if (
        path !== `/${user.schoolid}/parent/link-student` &&
        userRole === "parent" &&
        !user.guardian?.find((r) => r.status === "linked")
      ) {
        navigate(`/${user.schoolid}/parent/link-student`);
      } else if (path === "/email-verification" || path === "/reset-password") {
        return;
      } else if (path === "/login" || path === "/") {
        navigate(`/${user.schoolid}/${userRole}/dashboard`);
      } else if (findSchool !== user.schoolid) {
        alert("적합하지 않은 학교 경로입니다.");
        navigate("/login");
      } else if (findRole !== userRole) {
        alert("적합하지 않은 사용자유형 경로입니다.");
        navigate("/login");
      }
      setModalOpen(false);
    }
  }, [user, navigate, path, findSchool, findRole, checkParentChildMatch]);

  const login = useCallback(
    async (userInfo) =>
      authService.login(userInfo).then((user) => {
        setUser(user);
        setPermissions(user.permissions); // 권한 따로 저장
        if (user.role === "parent") {
          setSelectedChild(user.guardian[0]?.username || "");
          sessionStorage.setItem(
            "selectedChild",
            user.guardian[0]?.username || ""
          );
        }
        // console.log("userAuth", user);
      }),
    [authService]
  );

  const logout = useCallback(
    async () =>
      authService.logout().then(() => {
        setUser(undefined);
        setPermissions([]);
        setSelectedChild("");
        sessionStorage.removeItem("selectedChild");
      }),
    [authService]
  );

  //아무래도 보안상 이건 안 넣는게 좋겠다.
  /* 
  const byGoogleLogin = useCallback(async () => {
    try {
      const googleData = await googleLogin();
      console.log("googleData", googleData);

      const user = await authService.byGoogleLogin(googleData);
      setUser(user);
      setPermissions(user.permissions);

      if (user.role === "parent") {
        setSelectedChild(user.guardian[0]?.username || "");
        sessionStorage.setItem(
          "selectedChild",
          user.guardian[0]?.username || ""
        );
      }
    } catch (error) {
      console.error("Google login failed:", error);
      throw error;
    }
  }, [authService]);
*/
  //헷갈리니까 정리해보자.
  //action의 권한이 있으면 바로 허용, 없으면 다음으로 넘어감
  // owner 체크. 일치하면 허용, 없으면 불허
  // owner인지만 체크할면 hasPermission(username) 이렇게 사용
  // action 권한 있는지만 체크하려면 hasPermission(null, "can_create") 이렇게 사용
  // 권한 있는지 보고, 없으면 owner인지 체크하려면 hasPermission(username, "can_create") 이렇게 사용
  // 담임인지 확인하려면 담임 함수 안에 owner는 빼고 hasPermission(null, "can_create") 통과하며 true, 불통하면 담임 확인

  const hasPermission = (
    owner = null,
    action = null,
    isHomeroomTeacher = null
  ) => {
    if (user?.role === "admin") {
      return true;
    }

    // console.log("owner", owner);
    // console.log("action", action);
    // console.log("isHomeroomTeacher", isHomeroomTeacher);

    // 권한 체크: action이 들어오면 권한 유무 파악
    const hasPerm = action
      ? permissions.some((perm) => perm.menu?.link === address && perm[action])
      : true;

    // 권한이 있거나 action 없으면 true 리턴(허용)
    if (hasPerm) {
      return true;
    }

    // 담임 옵션이 제공되고 담임이면 허용
    if (isHomeroomTeacher) {
      return true;
    }

    // 소유자가 제공되지 않은 경우, 권한만 체크
    if (!owner) {
      return false;
    }

    // 권한이 없으면 소유자 여부 확인
    return user?.username === owner;
  };

  const handleSelectedChild = (child) => {
    if (child === "linkRequest") {
      navigate(`/${user.schoolid}/parent/link-student`);
    } else {
      setSelectedChild(child);
      sessionStorage.setItem("selectedChild", child);
    }
  };

  const refreshUser = useCallback(async () => {
    try {
      const { permissions, ...userData } = await authService.me();
      setUser(userData);
      setPermissions(permissions);

      // If user is parent, handle selected child
      if (userData.role === "parent") {
        const firstLinkedChild =
          userData.guardian?.find((g) => g.status === "linked")?.username || "";
        setSelectedChild(firstLinkedChild);
        sessionStorage.setItem("selectedChild", firstLinkedChild);
      }
    } catch (error) {
      console.error("Error refreshing user:", error);
      if (error.response?.status === 401) {
        setModalOpen(true);
      }
    }
  }, [authService]);

  useEffect(() => {
    if (
      user?.role === "parent" &&
      user.guardian?.some((g) => g.status === "linked") &&
      !sessionStorage.getItem("selectedChild")
    ) {
      refreshUser()
        .then(() => {
          console.log("Parent user data refreshed with linked child");
        })
        .catch((error) => {
          console.error("Error refreshing user data:", error);
        });
    }
  }, [user, refreshUser]);

  return (
    <AuthContext.Provider
      value={{
        googleUser,
        uid: googleUser && googleUser.uid,
        googleLogin,
        googleLogout,
        user,
        login,
        logout,
        address,
        permissions,
        hasPermission,
        selectedChild,
        handleSelectedChild,
        refreshUser,
        // byGoogleLogin,
      }}
    >
      {children}
      {/* 로그인 페이지가 아닐 때만 모달을 렌더링 */}
      {path !== "/login" && path !== "/" && path !== "/reset-password" && (
        <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
      )}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
export const fetchCsrfToken = () => csrfRef.current;
