export default class UserService {
  constructor(http) {
    this.http = http;
  }

  async findAllUsersByConditions(conditions = {}) {
    // conditions 객체를 쿼리 문자열로 변환
    // console.log("conditions", conditions);
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    // console.log("queryParams", queryParams);
    return this.http.fetch(`/api/auth/readallbyconditions?${queryParams}`, {
      method: "GET",
    });
  }

  async createUser(info) {
    return this.http.fetch(`/api/auth/createuser`, {
      method: "POST",
      body: JSON.stringify(info),
    });
  }

  async signupUser(info) {
    return this.http.fetch(`/api/auth/signup`, {
      method: "POST",
      body: JSON.stringify(info),
    });
  }

  async updateUser(userInfo) {
    return this.http.fetch(`/api/auth/update`, {
      method: "PUT",

      body: JSON.stringify(userInfo),
    });
  }

  async deleteUser(id) {
    return this.http.fetch(`/api/auth/delete`, {
      method: "DELETE",

      body: JSON.stringify({ id }),
    });
  }

  async userTeacherInfoQuery(userId) {
    const queryParams = Object.entries(userId)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/auth/read-user-teacher-info?${queryParams}`, {
      method: "GET",
    });
  }

  //권한 만들기 authoritymaking 페이지 함수들

  async readAllAuthorities() {
    return this.http.fetch(`/api/authoritymaking/readall`, {
      method: "GET",
    });
  }

  async createAuthorities(authorityInfo) {
    return this.http.fetch(`/api/authoritymaking/create`, {
      method: "POST",

      body: JSON.stringify(authorityInfo),
    });
  }

  async checkNewUsername(username) {
    return this.http.fetch(`/api/auth/check-username`, {
      method: "POST",
      body: JSON.stringify({ username }),
    });
  }

  async checkSchoolId(schoolid) {
    return this.http.fetch(`/api/auth/check-schoolid`, {
      method: "POST",
      body: JSON.stringify({ schoolid }),
    });
  }

  async userGuardianQuery(userId) {
    const queryParams = Object.entries(userId)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/auth/read-user-guardian?${queryParams}`, {
      method: "GET",
    });
  }

  async linkRequest(userInfo) {
    return this.http.fetch(`/api/auth/link-request`, {
      method: "PUT",
      body: JSON.stringify(userInfo),
    });
  }

  async cancelLinkRequest(userInfo) {
    return this.http.fetch(`/api/auth/cancel-link-request`, {
      method: "PUT",
      body: JSON.stringify(userInfo),
    });
  }

  async updateUserEmail(userInfo) {
    return this.http.fetch(`/api/auth/update-email`, {
      method: "PUT",

      body: JSON.stringify(userInfo),
    });
  }

  async findUsernameByEmail(email) {
    return this.http.fetch(`/api/auth/find-username-by-email`, {
      method: "POST",
      body: JSON.stringify({ email }),
    });
  }

  async resetPassword(userInfo) {
    return this.http.fetch(`/api/auth/reset-password-by-email`, {
      method: "PUT",

      body: JSON.stringify(userInfo),
    });
  }
}
