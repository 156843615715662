import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";
import FlexibleDataService from "../service/flexibleData";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useFlexibleData(conditions) {
  const queryClient = useQueryClient();
  const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
  const flexibleDataService = new FlexibleDataService(httpClient);
  const flexibleDataQuery = useQuery(
    ["flexibleData", conditions],
    () => flexibleDataService.findAllFlexibleData(conditions),
    {
      enabled: !!conditions?.schoolid && !!conditions?.main_category,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("flexibleData Query Data:", data);
      // },
    }
  );

  const createOrUpdateFlexibleData = useMutation(
    (data) => flexibleDataService.createOrUpdateFlexibleData(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["flexibleData", conditions]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const deleteFlexibleData = useMutation(
    (data) => flexibleDataService.deleteFlexibleData(data),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["flexibleData", conditions]);
      },
    }
  );

  return {
    flexibleDataQuery,
    createOrUpdateFlexibleData,
    deleteFlexibleData,
  };
}
