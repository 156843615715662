import { useEffect, useRef, useState } from "react";
import LoginBoard from "./components/LoginBoard";
import LoginMain from "./components/LoginMain";
import SignupForm from "./components/SignupForm";
import { CSSTransition } from "react-transition-group";
import styles from "./css/transitions.module.css";
import { verifyEmailLink } from "../../api/firebase";
import { useUIContext } from "../../context/UIContext";
import { useNavigate } from "react-router-dom";

function Login() {
  const [openSignup, setOpenSignup] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    schoolid: "",
    password: "",
    fullname: "",
    username: "",
    gender: "남",
    birthday: "",
    mobile: "",
    terms_accepted: false,
    role: "parent",
  });

  const [verifiedEmail, setVerifiedEmail] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);

  // Refs for nodeRef
  const signupRef = useRef(null);
  const loginRef = useRef(null);

  const { setIsLoadingModal } = useUIContext();
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    setOpenSignup(!openSignup);

    setVerifiedEmail("");

    const urlParams = new URLSearchParams(window.location.search);
    const isVerificationLink =
      urlParams.has("mode") && urlParams.has("oobCode");

    if (isVerificationLink && openSignup) {
      navigate("/login");
    }
  };

  const transitionProps = {
    timeout: 300,
    classNames: {
      enter: styles["fade-enter"],
      enterActive: styles["fade-enter-active"],
      exit: styles["fade-exit"],
      exitActive: styles["fade-exit-active"],
    },
    unmountOnExit: true,
  };

  // URL에서 인증 여부 확인
  useEffect(() => {
    if (isVerified) return;
    const url = window.location.href;

    // URL이 인증 링크인지 확인
    const urlParams = new URLSearchParams(window.location.search);
    const isVerificationLink =
      urlParams.has("mode") && urlParams.has("oobCode");

    if (isVerificationLink) {
      // 인증 링크가 맞다면 verifyEmailLink 실행

      setOpenSignup(true);
      setIsLoadingModal(true);
      verifyEmailLink(url)
        .then((result) => {
          setIsVerified(true);
          setFormData((prev) => ({
            ...prev,
            email: result.user.email,
            username: result.user.email,
          }));
          setVerifiedEmail(result.user.email); // 인증된 이메일 설정
          console.log("인증 성공");
          setIsLoadingModal(false);
        })
        .catch((error) => {
          setError(error.message || "인증에 실패했습니다.");
          console.error("인증 실패:", error);
          setIsLoadingModal(false);
        });
    } else {
      setOpenSignup(false);
      console.log("이 URL은 인증 링크가 아닙니다.");
    }
  }, [setIsLoadingModal, isVerified]);

  return (
    <div
      style={{ height: "700px", width: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      {/* {isVerified && console.log("isVerified", isVerified)} */}
      {error && console.log("error", error)}
      <div
        style={{
          height: "700px",
          maxWidth: "1000px",
          width: "100%",
        }}
        className="d-flex justify-content-space-between flex-wrap login align-items-center"
      >
        <div
          className="col-sm-7"
          style={{
            position: "relative",
          }}
        >
          {/* <CSSTransition in={!openSignup} {...transitionProps}> */}
          <LoginBoard />
          {/* </CSSTransition> */}
        </div>
        <div className="col-sm-5">
          <CSSTransition
            in={openSignup}
            {...transitionProps}
            nodeRef={signupRef}
          >
            <div ref={signupRef}>
              <SignupForm
                handleSignUp={handleSignUp}
                formData={formData}
                setFormData={setFormData}
                verifiedEmail={verifiedEmail}
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={!openSignup}
            {...transitionProps}
            nodeRef={loginRef}
          >
            <div ref={loginRef}>
              <LoginMain handleSignUp={handleSignUp} />
            </div>
          </CSSTransition>

          {/* {!openSignup && <LoginMain handleSignUp={handleSignUp} />} */}

          {/* {openSignup ? (
            <SignupForm setOpenSignup={setOpenSignup} />
          ) : (
            <LoginMain setOpenSignup={setOpenSignup} />
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Login;
