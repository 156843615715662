import React from "react";

//접속자 아이디
//접속자 권한
//버튼들

export default function ReportCardButtonBar({
  handleModifyMode,
  initiateApproval,
  handlePrint,
  adminMode,
  contentToPrint,
  modifyMode,
  showPageNumbers,
  setShowPageNumbers,
}) {
  return (
    <>
      <div style={{ width: "140px", fontSize: "13px" }} className="me-1">
        {/* 접속자: <span>테스트</span> <br /> 권한: <span>담임, 교감</span> */}
      </div>
      <div style={{ width: "110px" }} className="me-1 text-center">
        <button
          className="btn btn-warning d-none"
          type="button"
          onClick={adminMode}
          disabled={modifyMode}
        >
          관리자모드
        </button>
      </div>

      <div style={{ width: "100px" }} className="me-1 text-center">
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleModifyMode}
          name={modifyMode ? "modifyOn" : "modifyOff"}
        >
          {modifyMode ? "수정해제" : "수정모드"}
        </button>
      </div>
      <div style={{ width: "70px" }} className="me-1 text-center">
        <button
          className="btn btn-success"
          type="button"
          name="approval"
          onClick={initiateApproval}
          disabled={modifyMode}
        >
          결재
        </button>
      </div>
      <div style={{ width: "100px" }} className="me-1 text-center">
        <button
          className="btn btn-success"
          type="button"
          name="bulkApproval"
          onClick={initiateApproval}
          disabled={modifyMode}
        >
          일괄결재
        </button>
      </div>
      <div style={{ width: "70px" }} className="me-1 text-center">
        <button
          className="btn btn-info"
          type="button"
          onClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
          disabled={modifyMode}
        >
          인쇄
        </button>
      </div>
      <div className="form-check d-flex flex-column align-items-center ps-0">
        <label className="form-check-label" htmlFor="pageNumbers">
          쪽번호
        </label>
        <input
          className="form-check-input m-auto"
          type="checkbox"
          id="pageNumbers"
          checked={showPageNumbers}
          onChange={(e) => setShowPageNumbers(e.target.checked)}
        />
      </div>
    </>
  );
}
