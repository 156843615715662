//   이런식으로 쓰면 됨
//    if(Validate("#app select, #app input, #app textarea")){
//  } else {
//     setMessage()
//  }

export function validateByField(field) {
  let fieldsToValidate = document.querySelectorAll(field);

  Array.prototype.forEach.call(fieldsToValidate, function (el) {
    if (el.checkValidity()) {
      el.classList.remove("is-invalid");
    } else {
      el.classList.add("is-invalid");
    }
  });

  return Array.prototype.every.call(fieldsToValidate, function (el) {
    return el.checkValidity();
  });
}

//이런식으로 쓰면 된다.
// if (validateIfExistInObjsOfArr(rows, "idatschool", userInfo.idatschool)) {
//   setSuccess(
//     userInfo.idatschool + "학번은 이미 존재합니다. 다른 학번으로 해주세요."
//   );
//   setTimeout(() => {
//     setSuccess(null);
//   }, 4000);
//   setIsUploading(false);
// } else {
// }
//array 안에 object가 있는 데이터 대상이다.

export function validateIfExistInObjsOfArr(array, key, value) {
  // 값이 "" 일 때는 true를 반환
  if (value === "") {
    return false;
  }
  const item = array.find((item) => item[key] === value);

  return item !== undefined;
}

/* 이런 식으로 사용하면 됨. object에 validate 하고 싶은 key의 type과 존재하는지 확인하는 것임
const fieldsToValidate = [
  { key: 'id', type: 'number', required: true },
  { key: 'schoolid', type: 'string', required: true },
  // 나머지 필드도 이런 식으로 추가...
];

const errors = validateObject(object, fields);
if (errors.length > 0) {
  console.error("Invalid fields:", errors);
  return;
}
*/

export function validateObject(object, fields) {
  const errors = [];

  fields.forEach((field) => {
    const value = object[field.key];

    if (field.type && typeof value !== field.type) {
      errors.push(`${field.key} should be a ${field.type}`);
    }

    if (
      field.required &&
      (value === null || value === undefined || value === "")
    ) {
      errors.push(`${field.key} is required`);
    }
  });

  return errors;
}

//좀더 범용성있게 만들어보자. 만들었음. 자세한 활용법은 이 함수 밑에 넣는다.
export function validateObjectByFields(object, fields) {
  const errors = { type: {}, required: {} };

  fields.forEach((field) => {
    const value = object[field.key];
    const isNumber = field.type === "number" && !isNaN(Number(value)); // 숫자로 변환 가능한지 검사

    // 숫자 타입 검사: 문자열이 숫자로만 구성된 경우도 통과
    if (field.type && !(typeof value === field.type || isNumber)) {
      errors.type[
        field.key
      ] = `${field.name} 타입이 ${field.type} 이어야 합니다.`;
    }

    // 필수 필드 검사
    if (
      field.required &&
      (value === null || value === undefined || value === "")
    ) {
      errors.required[field.key] = `${field.name} 필수입니다.`;
    }
  });

  return errors;
}
/* 자세한 활용법 (서버로 제출하기 전에 쓰면 됨, 그리고 <form> 태그 안에 안 들어가는 경우(tr 이라든지)에 사용함)
 
   //addNewRow는 서버로 보내려는 object 이다.

const fieldsToValidate = [
      { key: "content", type: "string", required: true, name: "평가내용" },
      {
        key: "evaluation_type",
        type: "string",
        required: true,
        name: "평가제",
      },
      { key: "order", type: "number", required: true, name: "순서" },
    ];

        setValidateStyles({});

    const errorObject = validateObjectByFields(addNewRow, fieldsToValidate);
    const typeErrors = Object.values(errorObject.type || {});
    const requiredErrors = Object.values(errorObject.required || {});
    const allErrors = typeErrors.concat(requiredErrors);
    const errorMessageString = allErrors.join("\n");

    if (allErrors.length > 0) {
      const newStyles = {};
      Object.keys(errorObject.type).forEach((key) => (newStyles[key] = true));
      Object.keys(errorObject.required).forEach(
        (key) => (newStyles[key] = true)
      );

      //이건 상단에 상태로 {} 해 놓은 것으로, 아래 validate 할 form에 style={{}} 로 넣어두면 된다.
      setValidateStyles(newStyles);

      handleToastCenterTop(errorMessageString);
      return;
    }


//이런식
       <textarea
          className="form-control"
          name="content"
          value={addNewRow?.content || ""}
          onChange={handleChange}
          rows={1}
          required
          style={{
            backgroundColor: validateStyles?.content ? "pink" : undefined,
          }}
 
                  <select
          className="select-like-span ms-4 me-2"
          value={addNewRow?.evaluation_type || ""}
          name="evaluation_type"
          onChange={handleChange}
          style={{
            backgroundColor: validateStyles?.evaluation_type
              ? "pink"
              : undefined,
          }}
          required
        >



*/

// 범용 유효성 검사 함수
export function validateFields(data, fields) {
  const errors = {};

  fields.forEach((field) => {
    const value = data[field.key];
    const isNumber = field.type === "number" && !isNaN(Number(value));

    // 필수값 검증
    if (
      field.required &&
      (value === null || value === undefined || value === "")
    ) {
      errors[field.key] = `${field.name} 입력해 주세요.`;
    }

    // 타입 검증
    if (field.type && !(typeof value === field.type || isNumber)) {
      errors[field.key] = `${field.name}의 타입이 ${field.type}이어야 합니다.`;
    }
  });

  return errors; // 에러가 있는 필드만 반환
}

/* validateFields 사용법 참고

const [errors, setErrors] = useState({});

const handleSubmit = (e) => {
  e.preventDefault();

  const fieldsToValidate = [
    { key: "email", type: "string", required: true, name: "이메일" },
    { key: "password", type: "string", required: true, name: "비밀번호" },
    { key: "name", type: "string", required: true, name: "이름" },
    { key: "birthday", type: "string", required: true, name: "생년월일" },
    { key: "mobile", type: "string", required: true, name: "휴대폰 번호" },
    { key: "termsAccepted", type: "boolean", required: true, name: "약관 동의" },
  ];

  const validationErrors = validateFields(formData, fieldsToValidate);

  if (Object.keys(validationErrors).length > 0) {
    setErrors(validationErrors); // 에러 상태 업데이트
    return;
  }

  // 유효성 검사 통과
  console.log("폼 제출 성공:", formData);
};

// 필드별 에러 표시
const renderError = (key) => {
  return errors[key] ? <span style={{ color: "red" }}>{errors[key]}</span> : null;
};

form onSubmit={handleSubmit}>
  <input
    type="text"
    name="email"
    value={formData.email}
    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
    placeholder="이메일 입력"
  />
  {renderError("email")}

  <input
    type="password"
    name="password"
    value={formData.password}
    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
    placeholder="비밀번호 입력"
  />
  {renderError("password")}

  <button type="submit">제출</button>
</form>

*/

export function validatePasswordParent(password) {
  // 최소 8자 이상
  const minLength = /.{8,}/;
  // 영문 포함
  const hasLetter = /[a-zA-Z]/;
  // 숫자 포함
  const hasNumber = /[0-9]/;
  // 특수문자 포함
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

  // 조건 충족 확인
  const conditionsMet = [hasLetter, hasNumber, hasSpecialChar].filter((regex) =>
    regex.test(password)
  ).length;

  // 모든 조건 검사
  return minLength.test(password) && conditionsMet >= 2;
}

export function validatePhoneNumber(phoneNumber) {
  const phoneRegex = /^\d{2,3}-\d{3,4}-\d{4}$/;
  return phoneRegex.test(phoneNumber);
}
